.account_wrap {
  min-height: 100%;
}
.custom-label-account > .ant-form-item-label > label{
  color: white;
  font-weight: 700;
}
.profile_top {
  background: url(/img/profile.jpg) top center no-repeat;
  background-size: 100% 100%;
  border-bottom: 1px solid #f3b72f;
  color: #fff;
  padding: 0.85rem 0;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 56px
}
.mainwallet_box {
  display: block;
  flex-direction: column;
  align-items: center;
  background: #044531;
  color: #fff;
  padding: 0.5rem 0;
}

.pro_title {
  color: #ffaf50;
}
.num {
  font-size: 20px;
}

.pro_wrap {
  float: left;
  width: 100%;
  margin-bottom: 55px;
  background: #07593f;
}

.pro_box:first-child {
  border-left: none;
}

.pro_box {
  background: #022f21;
  width: 50%;
  float: left;
  text-align: center;
  border-right: 1px solid #07593f;
  padding: 1rem 0;
  margin: 0 0 1px;
}

.clearfix {
  clear: both;
}

.wallBx {
  display: inline-block;
  text-align: center;
  min-width: 130px;
  margin: 0.4rem 0.2rem;
  width: 47%;
}

.wallTxt {
  background: #14805e;
  color: #fff;
  padding: 4px 0;
}

.wallBx > .wMain, .wallBx > .wTotal {
  background: #eec67d;
  background: -webkit-gradient(linear, left top, left bottom, from(#eec67d), to(#f6b52a));
  background: -moz-linear-gradient(top, #eec67d, #f6b52a);
  filter: progid: DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#eec67d', endColorstr='#f6b52a');
  background: -o-linear-gradient(top, #eec67d, #f6b52a);
  background: -webkit-linear-gradient(top, #eec67d, #f6b52a);
  background: -ms-linear-gradient(top, #eec67d, #f6b52a);
  background: linear-gradient(to bottom, #eec67d, #f6b52a);
  color: #000;
}