@media screen and (max-width: 767px){

  .content-history .history{
    overflow: auto;
    text-align: center;
  }
  .content-history {
    /*font-style: italic;*/
    padding: 20px;
    width: 100%;
  }
  .content-history-mb .withdraw-depo {
    background: #03607c;
    color: #fff;
    text-align: center;
    font-weight: 700;
    padding: 5px;
  }
  .content-history .history img {
    width: 50%;
  }
  .content-history .withdraw-depo {
    font-size: 24px;
  }
  /*.Component-common{*/
  /*  overflow-y: scroll;*/
  /*}*/
}
h1, h2, h3, h4, h5, h6{
  /*color: white!important;*/
}
