.boxleft-wrapper {
  width: 40%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 20px 10px;
  background: #fff;
}

@media (max-width: 625px) {
  .boxleft-wrapper {
    padding: 20px 10px 0;
    width: 100%;
  }
}

.boxleft-style-user {
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding-bottom: 30px;
}

@media (max-width: 625px) {
  .boxleft-style-user {
    width: 50%;
    align-items: flex-start;
    padding-bottom: 0;
  }
}

.boxleft-balance {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 30px 0;
}

@media (max-width: 625px) {
  .boxleft-balance {
    padding: 15px 0;
  }
}

.boxleft-userveri {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 30px 0;
}

@media (max-width: 625px) {
  .boxleft-userveri {
    display: none;
  }
}

.boxleft-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 20px;
  background: #f7f7f7;
}

.boxright-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 20px;
  background: #f7f7f7;
}

@media (max-width: 625px) {
  .boxleft-item {
    display: none;
  }
}

.boxleft-title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  margin: 0;
}

@media (max-width: 575px) {
  .boxleft-title {
    font-size: 15px;
  }
}

.boxleft-text {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #0071e3;
  margin: 0;
}

.boxleft-linewhite {
  border: 5px solid #fff;
  border-radius: 4px;
  margin: 10px 0;
}

@media (max-width:575px) {
  .boxleft-linewhite {
    display: none;
  }
}

.boxleft-line {
  border: 3px solid #fff;
  border-radius: 4px;
}

.boxleft-styleviri {
  display: flex;
  flex-direction: column;
  gap: 10px;
}


/* ===== SIDEBAR ===== */
.menu-boxleft-sidebar {
  /* width: 260px;
  background-color: #0d1b2a;
  padding: 20px; */
}

.menu-boxleft-profile {
  text-align: center;
  margin-bottom: 20px;
}

.menu-boxleft-profile img {
  width: 80px;
  border-radius: 50%;
}

.menu-boxleft-profile p {
  color: #a8dadc;
}

.menu-boxleft-menu {
  list-style: none;
  margin-top: 20px;
}

.menu-boxleft-menu li {
  padding: 12px;
  display: flex;
  align-items: center;
}

.menu-boxleft-menu li a {
  color: white;
  text-decoration: none;
  font-size: 16px;
  flex: 1;
}

.menu-boxleft-menu li.active {
  background-color: #f4a261;
  border-radius: 5px;
  padding-left: 10px;
}

.vip-badge-img {
  max-width: 120px;
  margin: -40px auto 10px;
  position: relative;
}

.mblvl-text-1 {
  color: #ffe27f;
  font-size: 16px;
}

.mblvl-text-2 {
  font-size: 26px;
  font-weight: 700;
  text-transform: uppercase;
}

.afmenu-box {
  background: #08202f;
  border-radius: 25px;
  padding: 25px;
}


.afmenu-box {
  background: #08202f;
  border-radius: 25px;
  padding: 25px;
}

ul.nav.afmenuside-nav {
  flex-direction: column;
}

ul.nav.afmenuside-nav li.nav-item {
  margin-bottom: 5px;
}

ul.nav.afmenuside-nav li.nav-item a.mslink {
  display: block;
  color: #fff;
  padding: 2px 10px;
  border-radius: 8px;
  font-size: 16px;
  height: 42px;
  display: flex;
  align-items: center;
  position: relative;
}

.afn-icon {
  margin-right: 15px;
}

.afn-icon img {
  max-height: 24px;
}

ul.nav.afmenuside-nav li.nav-item a.mslink.active,
ul.nav.afmenuside-nav li.nav-item a.mslink:hover {
  background: linear-gradient(to left, rgba(248, 236, 127, 1) 0%, rgba(201, 158, 66, 1) 100%);
  color: #0a1941;
  font-weight: 500;
}

ul.nav.afmenuside-nav li.nav-item a.mslink.active img,
ul.nav.afmenuside-nav li.nav-item a.mslink:hover img {
  filter: brightness(0) contrast(1);
}

a.mslink.expand-arrow::after {
  content: '\f107';
  font-family: 'Font Awesome 5 Free';
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  color: #194100;
  right: 15px;
  top: 14px;
  transform: rotate(180deg);
  transition: 0.3s;
}

a.mslink.expand-arrow.collapsed::after {
  content: '\f107';
  font-family: 'Font Awesome 5 Free';
  transform: none;
  transition: 0.3s;
}

.af-submenu-box {
  position: relative;
  padding: 0 5px 0 35px;
  margin-top: 5px;
}

.af-submenu-box::before {
  content: '';
  background: #78848b;
  height: 100%;
  width: 2px;
  margin: auto;
  position: absolute;
  left: 21px;
  top: 0;
}

.af-submenu-box a {
  display: block;
  color: #fff;
  margin: 3px 0;
  padding: 10px 15px;
  border-radius: 8px;
}

.af-submenu-box a:hover,
.af-submenu-box a.active {
  background: linear-gradient(to left, rgba(248, 236, 127, 1) 0%, rgba(201, 158, 66, 1) 100%);
  color: #0a1941;
}