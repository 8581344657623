.error h2 {
  color: rgb(220, 53, 69)
}

.success h2 {
  color: #14805E
}

.popup-history{
  border-radius: 10px;
  /* border: 2px solid hsl(40deg 2% 35%); */
  background: hsl(22deg 100% 4%);
}
.popup-history .content-history{
  /*font-style: italic;*/
  padding: 20px;
}
.popup-history .content-history thead{
  color: hsl(42deg 100% 50%);
}
.popup-history .content-history thead th{
  border-top: none;
}

.popup-history .content-history .table td{
  border-top: none;
  padding: 0 5px;
}

.popup-history .content-history .table thead th{
  border: none;
}
.ant-picker-header button {
  background: none !important;
}
.popup-history h1{
  margin-bottom: 10px;
  text-align: center;
  color: hsl(42deg 100% 50%);
}

.popup-common{
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  border: 2px solid #23232a;
}
.popup-common .content-common h3{
  color: #23232a;
  text-align: center;
}
.popup-common .content-common label{
  color: #000;
  /*font-style: italic;*/
  font-weight: 600;
}
@media screen and (max-width: 767px){
  #login{
    /*background-color: hsl(236deg 100% 14%) !important;*/
  }
  .login-input{
    background-color: #fff;
  }
}
