@media screen and (max-width: 767px){
  .content-withdraw{
    /*height: 132vh;*/
    overflow-y: scroll;
  }
  .content-withdraw .inputW{
    width: 100%;
  }
  .content-withdraw .input-withdraw{
    padding: 0 10px;
  }
  .content-withdraw .inputW .input_block{
    background: hsl(0, 0%, 90%);
    color: hsl(22deg 100% 4%);
    position: relative;
  }
  .content-withdraw .formGroup label{
    margin-bottom: 5px;
    color: hsl(42deg 100% 46%);
  }
  .content-withdraw .formGroup_btn button {
    width: 100%;
    background: hsl(42deg 100% 50%);
    color: hsl(43deg 100% 1%);
    padding: 5px;
    border: none;
    font-weight: 700;
    /*font-style: italic;*/
  }
  .input-dow-crop{
    position: relative;
  }
  .input-dow-crop .icon-dow{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .input-dow-crop .input_block{
    line-height: 18px;
  }
  
  .input-dow-crop .icon-dow img{
    width: 20px;
  }
  .input-dow-crop select.input_block {
    background: transparent !important;
    -webkit-appearance: none;
  }
  .input-dow-crop {
    background: url(./images/dropdown.png) 96% / 9% no-repeat #fff;
  }
}