@media screen and (max-width: 767px){
  form.ant-form.ant-form-vertical.form-deposit {
    width: 100%;
    padding: 10px;
  }
  span.ant-input-affix-wrapper.ant-input-password.ant-input-password-default {
    border: solid 1px #03607c !important;
  }
  .changepass input {
    border: none !important;
  }
  label.ant-form-item-required {
    /*color: #fff !important;*/
    margin-top: 10px !important;
  }
  .content-change-pass {
    padding: 0 !important;
    margin-top: 100px;
    /*font-style: italic;*/
  }

  .content-change-pass button {
    background: #ae7b00;
    /* font-style: italic;
    font-size: 24px; */
  }
  .content-change-pass .form-input{
    max-width: 240px;
    margin: auto;
  }
  .content-change-pass label{
    color: rgb(198 144 13);
  }
  .content-change-pass label span{
    color: hsl(0deg 100% 50%);
  }
  .content-change-pas .form-input input {
    width: 100%;
    padding: 5px;
}
}