.btn-red {
  flex-shrink: 0;
  height: 35px;
  background: hsl(359deg 95% 42%);
  font-weight: 500;
  -webkit-box-shadow: 0 3px 5px 0 rgba(26, 26, 26, 0.23);
  box-shadow: 0 3px 5px 0 rgba(26, 26, 26, 0.23);
  border-radius: 8px;
  border-width: 0;
  color: #fff;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  justify-content: space-around !important;
  display: flex !important;
  font-size: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}
.btn-gr-menu:hover {
  color: hsl(347deg 78% 42%);
}
.btn-gr-menu a {
  cursor: pointer;
}
.form-login {
  padding-right: 15px;
  margin: 10px 0;
}
.form-login .content-form {
  display: flex;
  align-items: end;
  justify-content: end;
  margin: 5px 0;
}
.form-login .content-form label {
  margin: 0 5px 0;
}
.form-login input {
  border-radius: 5px;
  border: 1px solid #eee;
}

.form-login .content-form button {
  margin-left: 10px;
  height: 30px;
}
.account-history {
  display: flex;
  justify-content: end;
  padding-right: 10px;
}
.account-history .item-account {
  padding: 0 5px;
  cursor: pointer;
}
.account-history img {
  width: 35px;
}
.col2-right .lst-btn {
  display: flex;
}
.col2-right .lst-btn .btn-dp {
  margin-right: 5px;
}
.main .content {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1224px;
  width: 100%;
}

.display_mb .dropdown {
  padding: 17px;
}

.sport-item:last-child {
  display: none;
}

/* @media screen and (max-width: 767px) { */
  .sport-item:last-child {
    display: block;
  }

  .show_menu_mb {
    transition: all 0.5s;
  }
  .box_menu {
    position: fixed;
    left: 0;
    top: 0;
    width: 350px;
    height: 100vh;
    z-index: 999;
    background: #000;
    transform: translatex(-100%);
    transition: 0.5s;
  }
  @media (max-width: 575px) {
      .box_menu {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    background: #000;
    transform: translatex(-100%);
    transition: 0.5s;
  }
  }
  .show_menu_mb.show .box_menu {
    transform: translatex(0);
  }
  .show_menu_mb.show .bg_menu {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9;
    background: rgb(0 0 0 / 66%);
  }
  .menu_mb .btn-show-menu {
    text-align: center;
    width: 30%;
  }
  .menu_mb .btn-show-menu a {
    text-align: left;
  }
  .menu_mb .btn-show-menu a img {
    width: 40px;
    height: auto;
  }
  .content-logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content-logo img {
    width: 100% !important;
  }
  .menu_mb .content-logo h4 {
    font-weight: 800;
    background: -webkit-linear-gradient(
      271deg,
      hsl(340deg 100% 52%),
      hsl(350deg 100% 71%) 80%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0 0 0 5px;
    font-size: 28px;
  }
  .display_flex.display_mb.menu_mb .img-noti {
    text-align: right;
    width: 30%;
  }
  .display_flex.display_mb.menu_mb img {
    width: 34px;
  }
  .list_menu {
    display: flex;
    flex-wrap: wrap;
  }
  .list_menu .mn_item {
    flex-basis: 100%;
    max-width: 100%;
    text-align: center;
    padding: 10px 0;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
  }

  .mn_item img {
    width: 60%;
    display: block;
    margin: auto;
  }
  .menu_mb {
    align-items: center;
    background: linear-gradient(to left, rgba(248,236,127,1) 0%, rgba(201,158,66,1) 100%) !important;
  }
  .content-detail-comp {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    background: hsl(22deg 100% 4%);
    -webkit-transform: translatex(-150px);
    transform: translatex(-150px);
    -webkit-transition: 0.5s;
    transition: 0.5s;
    transform: translatex(0);
  }
  .item-account {
    border-top: 2px solid #03607c;
    padding: 20px 0;
    text-align: center;
  }
  .lst-inf-acc {
    display: flex;
    text-align: center;
  }
  .lst-inf-acc.item-account img {
    width: 50%;
  }
  .value-wallet.item-account h5 {
    margin: 5px 0;
  }
  .content-banking {
    display: flex;
    padding: 20px 0;
    text-align: center;
  }
  .content-banking .item-wallet {
    flex-basis: 31.33%;
    padding: 0 10px;
  }
  .content-banking .item-wallet img {
    width: 50%;
  }
  .value-banking {
    display: flex;
    padding: 10px;
    text-align: center;
  }
  .item-value-banking {
    flex-basis: 25%;
    font-weight: 700;
  }
  .item-value-banking h6 {
    color: hsl(24deg 3% 36%);
    font-weight: 700;
  }
/* } */
.styled-tbh {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: #ffffff;
}
.styled-line-header {
  border: 1px solid #737374;
  height: 36px;
}
.btn-login {
  background: #0b2e41;
  height: 38px;
  width: 96px;
  margin: 0px 10px;
}
.styled-account-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  @media (max-width: 1000px) {
    padding-right: 0;
  }
}
.styled-account img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.styled-account {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
}

/* menu item */
.styled-menu-item-wrapper {
  width: 100%;
  height: 60px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  background: #fff;
}
.styled-menu-item-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  gap: 30px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #a3b1ba;
}
@media (max-width: 412px) {
  .styled-menu-item-list-item {
    padding: 0 14px;
    font-size: 14px;
    gap: 14px;
  }
}

/* Modalheader */

.modal-header-wrapper {
    width: 100%;
    height: 100%;
}
.modal-header-nav {
  position: relative;
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
.modal-header-styled-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.modal-header-link {
  font-size: 16px;
  line-height: 32px;
  font-weight: 700;
  color: #fff;
  margin: 0;
  cursor: pointer;
}
.modal-header-link:hover {
  color: #f78212;
}
.modal-header-styled-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}
@media (max-width: 575px) {
  .modal-header-styled-logo {
    margin-left: 0;
  }
}
.styledlogo {
  width: 110px;
  height: 35px;
}
@media (max-width: 575px) {
  .styledlogo {
    width: 90px;
    height: 30px;
  }
}
.modal-header-styled-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.modal-header-styled-grouplink {
  width: 100%;
  height: 80vh;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

/* newHeader */
.new-header-wrapper {
  /* display: flex; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #08202f;
  position:fixed;
  top:0;
  width:100%;
  z-index:100;
}
.new-header-styled-header {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* zindex: 999; */
}

.new-header-styled-header-menu-game {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #051621;
  /* zindex: 999; */
}

.btn-join-now {
  background: linear-gradient(to right, rgba(248, 236, 127, 1) 0%, rgba(201, 158, 66, 1) 100%);
  border: none;
  color: #0a1941;
  font-weight: 700;
  font-size: 16px;
  height: 32px;
  text-align: center;
  padding: 3px 6px;
  min-width: 100px;
  border-radius: 9px;
  margin: 0 4px;
  animation: glow 1.5s ease-in-out infinite;
}
.d-flex {
  display: flex;
}
.ml-12 {
  margin-left: 12px;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fs-20 {
  font-size: 20px;
}
.justify-between {
  justify-content: space-between;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.flex-column {
  flex-direction: column;
}
.t-white {
  color: #fff;
}
.new-header-styled-header-menu-feature {
  width: 100%;
  height: 90px;
  display: flex;
  padding: 10px 40px;
  flex-direction: row;
  justify-content: space-between;
  background: #000000;
  /* zindex: 999; */
}

@media (max-width: 575px) {
  .new-header-styled-header {
    height: 60px;
  }
}
.styled-logo-header {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
}
@media (max-width: 1000px) {
  .styled-logo-header {
    margin-left: 0;
}
}
@media (max-width: 575px) {
  .styled-logo-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
}
.new-header-styled-showmb {
  display: none;
}
@media (max-width: 575px) {
  .new-header-styled-showmb {
  display: flex;
  align-items: center;
}
}
.new-header-styled-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}
@media (max-width: 575px) {
  .new-header-styled-logo {
  margin-left: 0;
}
}

.new-header-styled-img-megaphone {
  width: 16px;
  height: 16px;
  margin: 0 10px;
}
.new-header-styled-img-home {
  width: 24px;
  height: 24px;
  margin: 0 10px;
}
.new-header-styled-img-logo {
  width: 200px;
  height: 50px;
}

.new-header-styled-img-deposit {
  width: 70px;
  height: 60px;
}
@media (max-width: 575px) {
  .new-header-styled-img-logo {
    width: 120px;
    height: 50px;
  }
}
.new-header-styled-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.new-header-styled-link-menu-game {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  gap: 50px;
}
@media (max-width: 575px) {
  .new-header-styled-link {
    display: none;
  }
}
.new-header-link {
  font-size: 16px;
  line-height: 32px;
  font-weight: 700;
  color: #ffffff;
  margin: 0;
  cursor: pointer;
}
@media (max-width: 575px) {
  .new-header-link {
    font-size: 14px;
    line-height: 18px;
  }
}
.new-header-link:hover {
  color: #f78212;
}
.new-header-form-no-login {
  height: 100%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 575px) {
  .new-header-form-no-login {
    justify-content: center;
    display: none;
  }
}
.new-header-styled-lang {
  display: flex;
}
@media (max-width: 575px) {
  .new-header-styled-lang {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.new-header-styled-date-time {
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
  text-align: right;
  color: #ffffff;
  margin: 3px;
}
.new-header-styled-show-menu {
  align-items: center;
  justify-content: center;
  display: none;
}
.new-header-styled-header-left {
  display: flex;
  margin-left: 80px;
  flex-direction: row;
  gap: 50px;
}
.new-header-styled-header-right {
  display: flex;
  margin-right: 80px;
  flex-direction: row;
  gap: 50px;
}
@media (max-width: 1024px) {
  .new-header-styled-header-left {
    width: 100%;
    gap: 0;
    justify-content: space-between;
    padding: 0 10px;
    margin-left: 0px !important;
  }
}
@media (max-width: 1024px) {
  .new-header-styled-header-right {
    display: none;
  }
  .new-header-styled-show-menu {
    display: flex !important;
    align-items: center;
  }
}
.new-header-styled-mb {
  display: none;
}
@media (max-width: 1024px) {
  .new-header-styled-mb {
    display: flex;
  }
}
.new-header-styled_show {
  display: flex;
  align-items: center;
}
@media (max-width: 575px) {
.new-header-styled_show {
  display: none;
}
}
/* TotalPlayNow */
.total-play-wrapper {
  width: 100%;
  max-height: 200px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #dfdfdf;
  padding: 20px;
  margin: 25px 0;
  border-radius: 12px;
}
@media (max-width: 575px) {
  .total-play-wrapper {
    padding: 20px 10px;
    margin: 10px 0;
  }
}
.total-play-title {
  font-size: 32px;
  line-height: 42px;
  font-weight: 800;
  color: #000;
  margin: 0;
}
@media (max-width: 575px) {
  .total-play-title {
    font-size: 20px;
    line-height: 28px;
  }
}
.total-play-text {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: #ff8202;
  margin: 0;
}
@media (max-width: 575px) {
  .total-play-text {
    font-size: 13px;
    line-height: 18px;
  }
}
.total-play-group {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
@media (max-width: 950px) {
  .total-play-group {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}

/* Testimonial */
.testimonial-wrapper {
  width: 100%;
  height: auto;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  background: #f3f5f6;
  margin: 25px 0;
}
.slideshow {
  display: none;
}

@media (max-width: 575px) {
  .testimonial-wrapper {
    background: none;
  }
}
@media (max-width: 760px) {
  /* .showpc {
      display: none;
    } */
  .slideshow {
    margin: 0 auto;
    overflow: hidden;
    max-width: 301px;
  }
  .slideshowSlider {
    white-space: nowrap;
    display: flex;
    gap: 10px;
    transition: ease 2000ms;
  }
  .slideshowDots {
    text-align: center;
  }

  .slideshowDot {
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    cursor: pointer;
    margin: 15px 5px 0px;
    background-color: #c4c4c4;
  }
}
@media (max-width: 768px) {
  .slideshow {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.testimonial-title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: #000;
  padding: 30px;
  margin: 0;
}
@media (max-width: 575px) {
  .testimonial-title {
    padding: 20px 20px 0px;
  }
}
.testimonial-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0 30px 30px;
}
@media (max-width: 1224px) {
  .testimonial-list {
    justify-content: space-evenly;
    padding: 0 10px 30px;
  }
}
@media (max-width: 760px) {
  .testimonial-list {
    display: none;
  }
}
.testimonial-line {
  border: 1px solid #b2bdc3;
  margin: 5px;
}
.testimonial-star {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.testimonial-item {
  max-width: 400px;
  width: 400px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 5px;
}
@media (max-width: 1224px) {
  .testimonial-item {
    width: 300px;
  }
}
@media (max-width: 1002px) {
  .testimonial-item {
    width: 100%;
  }
}
@media (max-width: 760px) {
  .testimonial-item {
    border: 1px solid #ccc;
    border-radius: 24px;
    display: inline-block;
    width: 298px;
    height: 330px;
    padding: 30px 20px;
    background-color: #fff;
  }
}
.testimonial-title-item {
  font-size: 32px;
  line-height: 36px;
  font-weight: 600;
  padding: 0;
  margin: 0;
  color: #000;
}
@media (max-width: 1003px) {
  .testimonial-title-item {
    font-size: 24px;
    line-height: 32px;
  }
}
.testimonial-detail-item {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #b2bdc3;
  margin: 0;
}
.testimonial-item-star {
  width: 15px;
  height: 15px;
}

/* Head */

.head-slideshow-wrapper {
  position: relative;
  max-width: 100%;
  width: 100%;
  height: auto;
  overflow: hidden;
}
.head-slick-slide {
  margin-right: 10px;
}
.head-styled-slider {
  margin: 40px;
}
.head-styled-banner {
  display: block;
}
.head-styled-banner2 {
  display: flex;
}
@media (max-width: 1124px) {
  .head-styled-banner {
    display: none !important;
  }
  .game-slot-content {
    display: none !important;
  }
  .head-styled-banner2 {
    display: none !important;
  }
}
@media (max-width: 1024px) {
  .head-styled-slider {
  margin: 20px;
  }
  .head-styled-banner {
    display: none !important;
  }
}
@media (max-width: 575px) {
  .head-styled-slider {
  margin: 10px;
  }
}
.head-slideshow-styled-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 20%;
}
@media (max-width: 920px) {
  .head-slideshow-styled-text {
    padding-left: 50px;
  }
}
@media (max-width: 575px) {
  .head-slideshow-styled-text {
    padding-left: 10px;
  }
}
.head-slideshow-text-new {
  padding: 0 10px;
  background: #0131a0;
  color: #fff;
}
.head-slideshow-text {
  padding-left: 15px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #000;
  margin: 0;
}
@media (max-width: 575px) {
  .head-slideshow-text {
    font-size: 11px;
    line-height: 16px;
    padding-left: 10px;
  }
}
.head-slideshow-text-more {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  text-decoration: underline;
  color: #000;
  padding: 0 40px 0 100px;
  cursor: pointer;
}
@media (max-width: 920px) {
  .head-slideshow-text-more {
    padding: 0 40px 0 50px;
  }
}
@media (max-width: 575px) {
  .head-slideshow-text-more {
    padding: 0 10px 0 20px;
  }
}
.head-slideshow-imgslide {
  max-width: 400px;
}
@media (max-width: 375px) {
  .head-slideshow-imgslide {
    max-width: 330px;
  }
}

/* Footer */
.footer-group {
  max-width: 1224px;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;
}
.footer-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0;
  gap: 15px;
}
@media (max-width: 925px) {
  .footer-top {
    flex-direction: column;
  }
}
.footer-top-text {
  max-width: 250px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #a3b1ba;
}
.footer-center-top-icon {
  max-width: 350px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}
.footer-center-top-icon:img {
  cursor: pointer;
}
.footer-security {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.footer-top-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 925px) {
  .footer-top-right {
    align-items: center;
  }
}
.footer-top-left {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 80px;
}
@media (max-width: 925px) {
  .footer-top-left {
    justify-content: space-evenly;
    gap: 50px;
  }
}
@media (max-width: 575px) {
  .footer-top-left {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 15px;
  }
}
.footer-href {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #a3b1ba;
}
.footer-href:hover {
  color: #000;
}
.footer-center-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 925px) {
  .footer-center-top {
    flex-direction: column;
    justify-content: space-evenly;
    gap: 15px;
  }
}
.footer-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #000;
}
.footer-center-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 50px;
}
@media (max-width: 575px) {
  .footer-center-right {
    flex-direction: column;
    gap: 15px;
  }
}
.footer-center {
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 925px) {
  .footer-center {
    padding: 0 6%;
  }
}
@media (max-width: 575px) {
  .footer-center {
    align-items: center;
    padding: 0;
  }
}
.footer-center-title-bottom {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #a3b1ba;
  margin: 20px 0;
}
@media (max-width: 575px) {
  .footer-center-title-bottom {
    margin: 10px 0;
  }
}
.footer-bottom {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #000;
  background-color: #f3f5f6;
}
.style-footer-bottom {
  max-width: 1224px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}
@media (max-width: 1023px) {
  .style-footer-bottom {
    padding: 20px 20px 80px;
  }
}
.style-footer-bottom-p {
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  color: #000;
}
.footer-style-link {
  display: flex;
  flex-direction: row;
  gap: 80px;
  @media (max-width: 920px) {
  }
  .footer-style-link {
    gap: 50px;
  }
}
.footer-style-group {
  display: flex;
  flex-direction: row;
  gap: 50px;
}
@media (max-width: 575px) {
  .footer-style-group {
    flex-direction: column;
    gap: 15px;
  }
}
/* Login */

.login-new-wrapper {
  max-width: 1224px;
  margin: 0 auto;
  padding: 10px;
  background: #f7f7f7;
}
.login-new-title {
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: #000;
  margin: 0;
}
.login-new-content {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
}
@media (max-width: 768px) {
  .login-new-content {
    flex-direction: column;
  }
}
.login-new-content-left {
  max-width: 30%;
  width: 100%;
  padding: 20px;
  border-radius: 12px;
  background: #fff;
}
@media (max-width: 1000px) {
  .login-new-content-left {
    max-width: 40%;
  }
}
@media (max-width: 768px) {
  .login-new-content-left {
    max-width: 100%;
  }
}
.login-new-content-right {
  max-width: 70%;
  width: 100%;
  border-radius: 12px;
  padding: 30px;
  border-radius: 12px;
  background: #fff;
  ::-webkit-scrollbar {
    width: 5px;
    height: 3px;
  }
}
  @media (max-width: 1000px) {
    .login-new-content-right {
      max-width: 60%;
    }
  }
  @media (max-width: 768px) {
    .login-new-content-right {
      padding: 20px;
      max-width: 100%;
    }
  }
.login-new-titlewc {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: #000;
}
.login-new-titlewc-p {
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #000;
}
.login-new-styled-bonus {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 0;
}
@media (max-width: 1050px) {
  .login-new-styled-bonus {
    width: 745px;
    justify-content: center;
  }
}
.login-new-styled-bonus-p {
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  text-align: center;
  margin: 0;
  color: #000;
}
.theme-box {
  width: 240px;
  height: 160px;
  padding: 10px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width: 575px) {
  .theme-box {
    width: 240px;
    height: 140px;
  }
}
.login-new-styled-bonus-img {
  width: 80px;
  height: 80px;
  margin-bottom: 5px;
}
.login-new-styled-input {
  width: 100%;
  height: 39px;
  padding: 0 14px;
  border-radius: 8px;
}
.login-new-styled-lable {
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  color: #80929e;
  margin-bottom: 8px;
}
.login-new-not-register {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #80929e;
}
.login-new-not-register-span {
  text-decoration: underline;
  color: #0071e3;
  cursor: pointer;
  margin-bottom: 14px;
}

.login-new-not-register-span:hover {
  color: #ff7e00;
}

.login-new-style-select {
  width: 100%;
  height: 39px;
  padding: 0 10px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #80929e;
}

.cursor-pointer {
  cursor: pointer !important;
}
.d-inline-flex {
  display: inline-flex !important;
}

.text-user {
  color: #ffe27f;
}