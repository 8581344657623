body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
jdiv.wrap_b0e8._orientationRight_c30e.__jivoMobileButton {
  bottom: 20px !important;
}
jdiv.button_f0b0 {
  margin: 0px 10px !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn-red {
  flex-shrink: 0;
  height: 35px;
  background: hsl(359deg 95% 42%);
  font-weight: 500;
  box-shadow: 0 3px 5px 0 rgba(26, 26, 26, 0.23);
  border-radius: 8px;
  border-width: 0;
  color: #fff;
  align-items: center !important;
  justify-content: space-around !important;
  display: flex !important;
  font-size: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}
.btn-gr-menu:hover {
  color: hsl(347deg 78% 42%);
}
.btn-gr-menu a {
  cursor: pointer;
}
.form-login {
  padding-right: 15px;
  margin: 10px 0;
}
.form-login .content-form {
  display: flex;
  align-items: end;
  justify-content: end;
  margin: 5px 0;
}
.form-login .content-form label {
  margin: 0 5px 0;
}
.form-login input {
  border-radius: 5px;
  border: 1px solid #eee;
}

.form-login .content-form button {
  margin-left: 10px;
  height: 30px;
}
.account-history {
  display: flex;
  justify-content: end;
  padding-right: 10px;
}
.account-history .item-account {
  padding: 0 5px;
  cursor: pointer;
}
.account-history img {
  width: 35px;
}
.col2-right .lst-btn {
  display: flex;
}
.col2-right .lst-btn .btn-dp {
  margin-right: 5px;
}
.main .content {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1224px;
  width: 100%;
}

.display_mb .dropdown {
  padding: 17px;
}

.sport-item:last-child {
  display: none;
}

/* @media screen and (max-width: 767px) { */
  .sport-item:last-child {
    display: block;
  }

  .show_menu_mb {
    transition: all 0.5s;
  }
  .box_menu {
    position: fixed;
    left: 0;
    top: 0;
    width: 350px;
    height: 100vh;
    z-index: 999;
    background: #000;
    transform: translatex(-100%);
    transition: 0.5s;
  }
  @media (max-width: 575px) {
      .box_menu {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    background: #000;
    transform: translatex(-100%);
    transition: 0.5s;
  }
  }
  .show_menu_mb.show .box_menu {
    transform: translatex(0);
  }
  .show_menu_mb.show .bg_menu {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9;
    background: rgb(0 0 0 / 66%);
  }
  .menu_mb .btn-show-menu {
    text-align: center;
    width: 30%;
  }
  .menu_mb .btn-show-menu a {
    text-align: left;
  }
  .menu_mb .btn-show-menu a img {
    width: 40px;
    height: auto;
  }
  .content-logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content-logo img {
    width: 100% !important;
  }
  .menu_mb .content-logo h4 {
    font-weight: 800;
    background: -webkit-linear-gradient(
      271deg,
      hsl(340deg 100% 52%),
      hsl(350deg 100% 71%) 80%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0 0 0 5px;
    font-size: 28px;
  }
  .display_flex.display_mb.menu_mb .img-noti {
    text-align: right;
    width: 30%;
  }
  .display_flex.display_mb.menu_mb img {
    width: 34px;
  }
  .list_menu {
    display: flex;
    flex-wrap: wrap;
  }
  .list_menu .mn_item {
    flex-basis: 100%;
    max-width: 100%;
    text-align: center;
    padding: 10px 0;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
  }

  .mn_item img {
    width: 60%;
    display: block;
    margin: auto;
  }
  .menu_mb {
    align-items: center;
    background: linear-gradient(to left, rgba(248,236,127,1) 0%, rgba(201,158,66,1) 100%) !important;
  }
  .content-detail-comp {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    background: hsl(22deg 100% 4%);
    transform: translatex(-150px);
    transition: 0.5s;
    transform: translatex(0);
  }
  .item-account {
    border-top: 2px solid #03607c;
    padding: 20px 0;
    text-align: center;
  }
  .lst-inf-acc {
    display: flex;
    text-align: center;
  }
  .lst-inf-acc.item-account img {
    width: 50%;
  }
  .value-wallet.item-account h5 {
    margin: 5px 0;
  }
  .content-banking {
    display: flex;
    padding: 20px 0;
    text-align: center;
  }
  .content-banking .item-wallet {
    flex-basis: 31.33%;
    padding: 0 10px;
  }
  .content-banking .item-wallet img {
    width: 50%;
  }
  .value-banking {
    display: flex;
    padding: 10px;
    text-align: center;
  }
  .item-value-banking {
    flex-basis: 25%;
    font-weight: 700;
  }
  .item-value-banking h6 {
    color: hsl(24deg 3% 36%);
    font-weight: 700;
  }
/* } */
.styled-tbh {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap: 5px;
  gap: 5px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: #ffffff;
}
.styled-line-header {
  border: 1px solid #737374;
  height: 36px;
}
.btn-login {
  background: #0b2e41;
  height: 38px;
  width: 96px;
  margin: 0px 10px;
}
.styled-account-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  @media (max-width: 1000px) {
    padding-right: 0;
  }
}
.styled-account img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.styled-account {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap: 5px;
  gap: 5px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
}

/* menu item */
.styled-menu-item-wrapper {
  width: 100%;
  height: 60px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  background: #fff;
}
.styled-menu-item-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  grid-gap: 30px;
  gap: 30px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #a3b1ba;
}
@media (max-width: 412px) {
  .styled-menu-item-list-item {
    padding: 0 14px;
    font-size: 14px;
    grid-gap: 14px;
    gap: 14px;
  }
}

/* Modalheader */

.modal-header-wrapper {
    width: 100%;
    height: 100%;
}
.modal-header-nav {
  position: relative;
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
.modal-header-styled-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.modal-header-link {
  font-size: 16px;
  line-height: 32px;
  font-weight: 700;
  color: #fff;
  margin: 0;
  cursor: pointer;
}
.modal-header-link:hover {
  color: #f78212;
}
.modal-header-styled-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}
@media (max-width: 575px) {
  .modal-header-styled-logo {
    margin-left: 0;
  }
}
.styledlogo {
  width: 110px;
  height: 35px;
}
@media (max-width: 575px) {
  .styledlogo {
    width: 90px;
    height: 30px;
  }
}
.modal-header-styled-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.modal-header-styled-grouplink {
  width: 100%;
  height: 80vh;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

/* newHeader */
.new-header-wrapper {
  /* display: flex; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #08202f;
  position:fixed;
  top:0;
  width:100%;
  z-index:100;
}
.new-header-styled-header {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* zindex: 999; */
}

.new-header-styled-header-menu-game {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #051621;
  /* zindex: 999; */
}

.btn-join-now {
  background: linear-gradient(to right, rgba(248, 236, 127, 1) 0%, rgba(201, 158, 66, 1) 100%);
  border: none;
  color: #0a1941;
  font-weight: 700;
  font-size: 16px;
  height: 32px;
  text-align: center;
  padding: 3px 6px;
  min-width: 100px;
  border-radius: 9px;
  margin: 0 4px;
  animation: glow 1.5s ease-in-out infinite;
}
.d-flex {
  display: flex;
}
.ml-12 {
  margin-left: 12px;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fs-20 {
  font-size: 20px;
}
.justify-between {
  justify-content: space-between;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.flex-column {
  flex-direction: column;
}
.t-white {
  color: #fff;
}
.new-header-styled-header-menu-feature {
  width: 100%;
  height: 90px;
  display: flex;
  padding: 10px 40px;
  flex-direction: row;
  justify-content: space-between;
  background: #000000;
  /* zindex: 999; */
}

@media (max-width: 575px) {
  .new-header-styled-header {
    height: 60px;
  }
}
.styled-logo-header {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
}
@media (max-width: 1000px) {
  .styled-logo-header {
    margin-left: 0;
}
}
@media (max-width: 575px) {
  .styled-logo-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
}
.new-header-styled-showmb {
  display: none;
}
@media (max-width: 575px) {
  .new-header-styled-showmb {
  display: flex;
  align-items: center;
}
}
.new-header-styled-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}
@media (max-width: 575px) {
  .new-header-styled-logo {
  margin-left: 0;
}
}

.new-header-styled-img-megaphone {
  width: 16px;
  height: 16px;
  margin: 0 10px;
}
.new-header-styled-img-home {
  width: 24px;
  height: 24px;
  margin: 0 10px;
}
.new-header-styled-img-logo {
  width: 200px;
  height: 50px;
}

.new-header-styled-img-deposit {
  width: 70px;
  height: 60px;
}
@media (max-width: 575px) {
  .new-header-styled-img-logo {
    width: 120px;
    height: 50px;
  }
}
.new-header-styled-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 25px;
  gap: 25px;
}

.new-header-styled-link-menu-game {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  grid-gap: 50px;
  gap: 50px;
}
@media (max-width: 575px) {
  .new-header-styled-link {
    display: none;
  }
}
.new-header-link {
  font-size: 16px;
  line-height: 32px;
  font-weight: 700;
  color: #ffffff;
  margin: 0;
  cursor: pointer;
}
@media (max-width: 575px) {
  .new-header-link {
    font-size: 14px;
    line-height: 18px;
  }
}
.new-header-link:hover {
  color: #f78212;
}
.new-header-form-no-login {
  height: 100%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 575px) {
  .new-header-form-no-login {
    justify-content: center;
    display: none;
  }
}
.new-header-styled-lang {
  display: flex;
}
@media (max-width: 575px) {
  .new-header-styled-lang {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.new-header-styled-date-time {
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
  text-align: right;
  color: #ffffff;
  margin: 3px;
}
.new-header-styled-show-menu {
  align-items: center;
  justify-content: center;
  display: none;
}
.new-header-styled-header-left {
  display: flex;
  margin-left: 80px;
  flex-direction: row;
  grid-gap: 50px;
  gap: 50px;
}
.new-header-styled-header-right {
  display: flex;
  margin-right: 80px;
  flex-direction: row;
  grid-gap: 50px;
  gap: 50px;
}
@media (max-width: 1024px) {
  .new-header-styled-header-left {
    width: 100%;
    grid-gap: 0;
    gap: 0;
    justify-content: space-between;
    padding: 0 10px;
    margin-left: 0px !important;
  }
}
@media (max-width: 1024px) {
  .new-header-styled-header-right {
    display: none;
  }
  .new-header-styled-show-menu {
    display: flex !important;
    align-items: center;
  }
}
.new-header-styled-mb {
  display: none;
}
@media (max-width: 1024px) {
  .new-header-styled-mb {
    display: flex;
  }
}
.new-header-styled_show {
  display: flex;
  align-items: center;
}
@media (max-width: 575px) {
.new-header-styled_show {
  display: none;
}
}
/* TotalPlayNow */
.total-play-wrapper {
  width: 100%;
  max-height: 200px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #dfdfdf;
  padding: 20px;
  margin: 25px 0;
  border-radius: 12px;
}
@media (max-width: 575px) {
  .total-play-wrapper {
    padding: 20px 10px;
    margin: 10px 0;
  }
}
.total-play-title {
  font-size: 32px;
  line-height: 42px;
  font-weight: 800;
  color: #000;
  margin: 0;
}
@media (max-width: 575px) {
  .total-play-title {
    font-size: 20px;
    line-height: 28px;
  }
}
.total-play-text {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: #ff8202;
  margin: 0;
}
@media (max-width: 575px) {
  .total-play-text {
    font-size: 13px;
    line-height: 18px;
  }
}
.total-play-group {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
@media (max-width: 950px) {
  .total-play-group {
    flex-direction: column;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
  }
}

/* Testimonial */
.testimonial-wrapper {
  width: 100%;
  height: auto;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  background: #f3f5f6;
  margin: 25px 0;
}
.slideshow {
  display: none;
}

@media (max-width: 575px) {
  .testimonial-wrapper {
    background: none;
  }
}
@media (max-width: 760px) {
  /* .showpc {
      display: none;
    } */
  .slideshow {
    margin: 0 auto;
    overflow: hidden;
    max-width: 301px;
  }
  .slideshowSlider {
    white-space: nowrap;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    transition: ease 2000ms;
  }
  .slideshowDots {
    text-align: center;
  }

  .slideshowDot {
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    cursor: pointer;
    margin: 15px 5px 0px;
    background-color: #c4c4c4;
  }
}
@media (max-width: 768px) {
  .slideshow {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.testimonial-title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: #000;
  padding: 30px;
  margin: 0;
}
@media (max-width: 575px) {
  .testimonial-title {
    padding: 20px 20px 0px;
  }
}
.testimonial-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0 30px 30px;
}
@media (max-width: 1224px) {
  .testimonial-list {
    justify-content: space-evenly;
    padding: 0 10px 30px;
  }
}
@media (max-width: 760px) {
  .testimonial-list {
    display: none;
  }
}
.testimonial-line {
  border: 1px solid #b2bdc3;
  margin: 5px;
}
.testimonial-star {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}
.testimonial-item {
  max-width: 400px;
  width: 400px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 5px;
}
@media (max-width: 1224px) {
  .testimonial-item {
    width: 300px;
  }
}
@media (max-width: 1002px) {
  .testimonial-item {
    width: 100%;
  }
}
@media (max-width: 760px) {
  .testimonial-item {
    border: 1px solid #ccc;
    border-radius: 24px;
    display: inline-block;
    width: 298px;
    height: 330px;
    padding: 30px 20px;
    background-color: #fff;
  }
}
.testimonial-title-item {
  font-size: 32px;
  line-height: 36px;
  font-weight: 600;
  padding: 0;
  margin: 0;
  color: #000;
}
@media (max-width: 1003px) {
  .testimonial-title-item {
    font-size: 24px;
    line-height: 32px;
  }
}
.testimonial-detail-item {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #b2bdc3;
  margin: 0;
}
.testimonial-item-star {
  width: 15px;
  height: 15px;
}

/* Head */

.head-slideshow-wrapper {
  position: relative;
  max-width: 100%;
  width: 100%;
  height: auto;
  overflow: hidden;
}
.head-slick-slide {
  margin-right: 10px;
}
.head-styled-slider {
  margin: 40px;
}
.head-styled-banner {
  display: block;
}
.head-styled-banner2 {
  display: flex;
}
@media (max-width: 1124px) {
  .head-styled-banner {
    display: none !important;
  }
  .game-slot-content {
    display: none !important;
  }
  .head-styled-banner2 {
    display: none !important;
  }
}
@media (max-width: 1024px) {
  .head-styled-slider {
  margin: 20px;
  }
  .head-styled-banner {
    display: none !important;
  }
}
@media (max-width: 575px) {
  .head-styled-slider {
  margin: 10px;
  }
}
.head-slideshow-styled-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 20%;
}
@media (max-width: 920px) {
  .head-slideshow-styled-text {
    padding-left: 50px;
  }
}
@media (max-width: 575px) {
  .head-slideshow-styled-text {
    padding-left: 10px;
  }
}
.head-slideshow-text-new {
  padding: 0 10px;
  background: #0131a0;
  color: #fff;
}
.head-slideshow-text {
  padding-left: 15px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #000;
  margin: 0;
}
@media (max-width: 575px) {
  .head-slideshow-text {
    font-size: 11px;
    line-height: 16px;
    padding-left: 10px;
  }
}
.head-slideshow-text-more {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  text-decoration: underline;
  color: #000;
  padding: 0 40px 0 100px;
  cursor: pointer;
}
@media (max-width: 920px) {
  .head-slideshow-text-more {
    padding: 0 40px 0 50px;
  }
}
@media (max-width: 575px) {
  .head-slideshow-text-more {
    padding: 0 10px 0 20px;
  }
}
.head-slideshow-imgslide {
  max-width: 400px;
}
@media (max-width: 375px) {
  .head-slideshow-imgslide {
    max-width: 330px;
  }
}

/* Footer */
.footer-group {
  max-width: 1224px;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;
}
.footer-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0;
  grid-gap: 15px;
  gap: 15px;
}
@media (max-width: 925px) {
  .footer-top {
    flex-direction: column;
  }
}
.footer-top-text {
  max-width: 250px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #a3b1ba;
}
.footer-center-top-icon {
  max-width: 350px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
}
.footer-center-top-icon:img {
  cursor: pointer;
}
.footer-security {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
}
.footer-top-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 925px) {
  .footer-top-right {
    align-items: center;
  }
}
.footer-top-left {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-gap: 80px;
  gap: 80px;
}
@media (max-width: 925px) {
  .footer-top-left {
    justify-content: space-evenly;
    grid-gap: 50px;
    gap: 50px;
  }
}
@media (max-width: 575px) {
  .footer-top-left {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    grid-gap: 15px;
    gap: 15px;
  }
}
.footer-href {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #a3b1ba;
}
.footer-href:hover {
  color: #000;
}
.footer-center-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 925px) {
  .footer-center-top {
    flex-direction: column;
    justify-content: space-evenly;
    grid-gap: 15px;
    gap: 15px;
  }
}
.footer-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #000;
}
.footer-center-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-gap: 50px;
  gap: 50px;
}
@media (max-width: 575px) {
  .footer-center-right {
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
  }
}
.footer-center {
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 925px) {
  .footer-center {
    padding: 0 6%;
  }
}
@media (max-width: 575px) {
  .footer-center {
    align-items: center;
    padding: 0;
  }
}
.footer-center-title-bottom {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #a3b1ba;
  margin: 20px 0;
}
@media (max-width: 575px) {
  .footer-center-title-bottom {
    margin: 10px 0;
  }
}
.footer-bottom {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #000;
  background-color: #f3f5f6;
}
.style-footer-bottom {
  max-width: 1224px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}
@media (max-width: 1023px) {
  .style-footer-bottom {
    padding: 20px 20px 80px;
  }
}
.style-footer-bottom-p {
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  color: #000;
}
.footer-style-link {
  display: flex;
  flex-direction: row;
  grid-gap: 80px;
  gap: 80px;
  @media (max-width: 920px) {
  }
  .footer-style-link {
    grid-gap: 50px;
    gap: 50px;
  }
}
.footer-style-group {
  display: flex;
  flex-direction: row;
  grid-gap: 50px;
  gap: 50px;
}
@media (max-width: 575px) {
  .footer-style-group {
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
  }
}
/* Login */

.login-new-wrapper {
  max-width: 1224px;
  margin: 0 auto;
  padding: 10px;
  background: #f7f7f7;
}
.login-new-title {
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: #000;
  margin: 0;
}
.login-new-content {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  grid-gap: 20px;
  gap: 20px;
}
@media (max-width: 768px) {
  .login-new-content {
    flex-direction: column;
  }
}
.login-new-content-left {
  max-width: 30%;
  width: 100%;
  padding: 20px;
  border-radius: 12px;
  background: #fff;
}
@media (max-width: 1000px) {
  .login-new-content-left {
    max-width: 40%;
  }
}
@media (max-width: 768px) {
  .login-new-content-left {
    max-width: 100%;
  }
}
.login-new-content-right {
  max-width: 70%;
  width: 100%;
  border-radius: 12px;
  padding: 30px;
  border-radius: 12px;
  background: #fff;
  ::-webkit-scrollbar {
    width: 5px;
    height: 3px;
  }
}
  @media (max-width: 1000px) {
    .login-new-content-right {
      max-width: 60%;
    }
  }
  @media (max-width: 768px) {
    .login-new-content-right {
      padding: 20px;
      max-width: 100%;
    }
  }
.login-new-titlewc {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: #000;
}
.login-new-titlewc-p {
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #000;
}
.login-new-styled-bonus {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
  padding: 10px 0;
}
@media (max-width: 1050px) {
  .login-new-styled-bonus {
    width: 745px;
    justify-content: center;
  }
}
.login-new-styled-bonus-p {
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  text-align: center;
  margin: 0;
  color: #000;
}
.theme-box {
  width: 240px;
  height: 160px;
  padding: 10px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width: 575px) {
  .theme-box {
    width: 240px;
    height: 140px;
  }
}
.login-new-styled-bonus-img {
  width: 80px;
  height: 80px;
  margin-bottom: 5px;
}
.login-new-styled-input {
  width: 100%;
  height: 39px;
  padding: 0 14px;
  border-radius: 8px;
}
.login-new-styled-lable {
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  color: #80929e;
  margin-bottom: 8px;
}
.login-new-not-register {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #80929e;
}
.login-new-not-register-span {
  text-decoration: underline;
  color: #0071e3;
  cursor: pointer;
  margin-bottom: 14px;
}

.login-new-not-register-span:hover {
  color: #ff7e00;
}

.login-new-style-select {
  width: 100%;
  height: 39px;
  padding: 0 10px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #80929e;
}

.cursor-pointer {
  cursor: pointer !important;
}
.d-inline-flex {
  display: inline-flex !important;
}

.text-user {
  color: #ffe27f;
}
.error h2 {
  color: rgb(220, 53, 69)
}

.success h2 {
  color: #14805E
}

.popup-history{
  border-radius: 10px;
  /* border: 2px solid hsl(40deg 2% 35%); */
  background: hsl(22deg 100% 4%);
}
.popup-history .content-history{
  /*font-style: italic;*/
  padding: 20px;
}
.popup-history .content-history thead{
  color: hsl(42deg 100% 50%);
}
.popup-history .content-history thead th{
  border-top: none;
}

.popup-history .content-history .table td{
  border-top: none;
  padding: 0 5px;
}

.popup-history .content-history .table thead th{
  border: none;
}
.ant-picker-header button {
  background: none !important;
}
.popup-history h1{
  margin-bottom: 10px;
  text-align: center;
  color: hsl(42deg 100% 50%);
}

.popup-common{
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  border: 2px solid #23232a;
}
.popup-common .content-common h3{
  color: #23232a;
  text-align: center;
}
.popup-common .content-common label{
  color: #000;
  /*font-style: italic;*/
  font-weight: 600;
}
@media screen and (max-width: 767px){
  #login{
    /*background-color: hsl(236deg 100% 14%) !important;*/
  }
  .login-input{
    background-color: #fff;
  }
}

.error h2 {
  color: rgb(220, 53, 69)
}

.success h2 {
  color: #14805E
}
.title-header {
  flex-grow: 1;
  font-family: 'AIFont' !important
}
@media screen and (max-width: 767px){
  .Component-common {
    position: fixed;
    top: 0;
    height: 100%;
    background: #fff;
    z-index: 9;
    /*font-style: italic;*/
    width: 100%;
  }
  .header-detail_mb{
    display: flex;
    text-align: center;
    align-items: center;
    align-content: center;
    margin: auto;
    justify-content: center;
    padding: 0;
    /*background: #140700;*/
    min-height: 50px;
  }
  .header-detail_mb img {
    width: 40%;
  }
  .header-detail_mb h5 {
    margin: 0;
  }
  .header-detail_mb div {
    flex-basis: 33.33%;
  }
  .Component-common .title-name{
    flex-basis: 55%;
  }
  .ant-picker,label,input{
    width: 100%;
  }
  .ant-form-item .ant-form-item-label > label{
    width: 100%;
  }
  .ant-form .ant-form-item{
    width: 100%;
  }
}
#custom-primary-button-deposit{
      box-shadow: unset!important;
    /*background: unset!important;*/
    /*background: unset!important;*/
    /* border: 1px solid rgba(255, 214, 0, 0.2); */
    margin-top: unset!important;
}

@media screen and (max-width: 767px){
  form.ant-form.ant-form-vertical.form-deposit {
    width: 100%;
    padding: 10px;
  }
  span.ant-input-affix-wrapper.ant-input-password.ant-input-password-default {
    border: solid 1px #03607c !important;
  }
  .changepass input {
    border: none !important;
  }
  label.ant-form-item-required {
    /*color: #fff !important;*/
    margin-top: 10px !important;
  }
  .content-change-pass {
    padding: 0 !important;
    margin-top: 100px;
    /*font-style: italic;*/
  }

  .content-change-pass button {
    background: #ae7b00;
    /* font-style: italic;
    font-size: 24px; */
  }
  .content-change-pass .form-input{
    max-width: 240px;
    margin: auto;
  }
  .content-change-pass label{
    color: rgb(198 144 13);
  }
  .content-change-pass label span{
    color: hsl(0deg 100% 50%);
  }
  .content-change-pas .form-input input {
    width: 100%;
    padding: 5px;
}
}
@media screen and (max-width: 767px){
  .content-withdraw{
    /*height: 132vh;*/
    overflow-y: scroll;
  }
  .content-withdraw .inputW{
    width: 100%;
  }
  .content-withdraw .input-withdraw{
    padding: 0 10px;
  }
  .content-withdraw .inputW .input_block{
    background: hsl(0, 0%, 90%);
    color: hsl(22deg 100% 4%);
    position: relative;
  }
  .content-withdraw .formGroup label{
    margin-bottom: 5px;
    color: hsl(42deg 100% 46%);
  }
  .content-withdraw .formGroup_btn button {
    width: 100%;
    background: hsl(42deg 100% 50%);
    color: hsl(43deg 100% 1%);
    padding: 5px;
    border: none;
    font-weight: 700;
    /*font-style: italic;*/
  }
  .input-dow-crop{
    position: relative;
  }
  .input-dow-crop .icon-dow{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .input-dow-crop .input_block{
    line-height: 18px;
  }
  
  .input-dow-crop .icon-dow img{
    width: 20px;
  }
  .input-dow-crop select.input_block {
    background: transparent !important;
    -webkit-appearance: none;
  }
  .input-dow-crop {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAEBklEQVRogeWbT4gWZRzHP46mq+4ewo1wPYjgYcH8R5DgGuwrEnhUEgoLb13qIHkou4h0MA+KhJ6ig4QgGKl0CCzwYHswCPMf7CEQD65UKxERLYYWz/KdaZjfzMvMvM/z+s7sBwaW+T0z8/vM8+7zzvN7n2GhsWh8fNy38svACPA6sBt43rTI53fgG+BL4E/gx9xWNZmenp4/cImn870E7AH2AltMtBzuxryrzfET8BVwEbjjS7xXYffxeB94Gxgy0d7You0j4AvgpOuoXk+6eHR01OwswVrgLSWyw+MnJY8l+jd5E/gL+BX4I6ddV2ZnZ+fDUbdGBbwKTAGngVX5TYKwStecUg61qCL8HHAMuAqsMdH+sUY5HFNOlSgr7Aal68CH7t/ARPvPYuVyXbmVpozwJuAKsNVEnj1bldsmX8Ib9N242kQGh9XKcUOvwpt198ZMZPAYU66b6wov1VdOE2RjxpTzUhNJUSR8FNho9g4+G5V7IXnC+4CDZm9zOCiHXLLC7jvufIDHxH4yJIfcZ4Uo8/fenJvQRApd0jsmgOMtkI1xLhPZnbHwIuAAsNwc1lycywG5GeEXgP0tko3ZL7eEWHhfwweqIoayI3akSsOhggPawKF0mSlS1WJdi4XXyXEeJ7zeNGkf69PCuxaAcOIYqSbVdhJHVyCrVcVL4erHvwQc5eeAF1XIq0vi6ISf9JjQMs1QfjARP7wCfN7jmRJH86xZA1dT+g7oBJDt6NyV6lbd8DVRcD+tXAYmTaQ+kzrniMdzep0ZucS+9tTTHZ3LqywBpoLDHno67tlhE/FAiLlvLz0drGdjQk32456uIt0J2bMxIasbIxWkOyEGqDyiwD+dxB/vnSbyPztDf4zTjk541oT9shK4VDCQTSq20kT8kjg64e8DX4yCnu5Hz8YkjpGeZPrBsHpzu7ZLoQeoFImje5b+2YTD4XrzW519RR+vmzhGWjdxzzQJx4o+y95Lrw2JtFzohGnWHk7IMRF2XNC8s23MyY2s8G/AuRYKn5NbQiz8L3AW+Nsc0lycy1m5GWG0HOiDFgk7l6nszrTwUy31e2oObR6FLtnJwwPgjYYPYHNycC6GvNmSG9VOmb3N4VR2ZE6TJ+w4Atw2ewef28q9kCLhx1ohO2Mig8uMcn7cLcMiYcdN4LWGSM8o15smkqGbsOOuVrU/NJHB4aFyvFsmozIlnlu6ezdM5NlzQ7ndKptJ2ZqWW4K/DfjEw08zPniiXLZVfT2gShHvH+CwCm6533F94oFyOKycKlGnanlNS5zeAx6ZaDge6ZoTyqEWdcu094Ez+t31s8BPZnO6xg5d875pUYFeX85wlYR3gE89vMaTZSBf44m5o+3jQX1Ra2EC/AfpsLCes6v+uwAAAABJRU5ErkJggg==) 96% / 9% no-repeat #fff;
  }
}
@media screen and (max-width: 767px){

  .content-history .history{
    overflow: auto;
    text-align: center;
  }
  .content-history {
    /*font-style: italic;*/
    padding: 20px;
    width: 100%;
  }
  .content-history-mb .withdraw-depo {
    background: #03607c;
    color: #fff;
    text-align: center;
    font-weight: 700;
    padding: 5px;
  }
  .content-history .history img {
    width: 50%;
  }
  .content-history .withdraw-depo {
    font-size: 24px;
  }
  /*.Component-common{*/
  /*  overflow-y: scroll;*/
  /*}*/
}
h1, h2, h3, h4, h5, h6{
  /*color: white!important;*/
}

@media screen and (max-width: 767px){
  .content-detail-banking{
    font-weight: 700;
    /*font-style: italic;*/
    font-size: 22px;
  }
  .content-detail-banking .label-bank-detail {
    padding: 10px;
    background: #03607c;
    text-align: center;
    color: #fff;
  }
  .content-detail-banking .value-detail-banking {
    padding: 20px;
    font-weight: 600 !important;
}
  .row-content-bank {
    display: flex;
  }
  .row-content-bank img {
    width: 25%;
  }
  .row-content-bank .title {
    flex-basis: 50%;
}

}
@media screen and (max-width: 767px){
  .content-account{
    /*font-style: italic;*/
    font-weight: 700;
  }
  .name-logo-account{
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .name-logo-account img {
    width: 20%;
  }
  .name-logo-account h5 {
    margin: 0 15px;
  }
  .form-acc {
    text-align: left;
    /*max-width: 200px;*/
    margin: auto;
    /*color: #ab7600;*/
    font-style: 
    italic;
  }
  .form-acc span{
    color: #ef001b;
  }

}
@media screen and (max-width: 767px){
  .content-account{
    /*font-style: italic;*/
    font-weight: 700;
  }
  .name-logo-account{
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .name-logo-account img {
    width: 20%;
  }
  .name-logo-account h5 {
    margin: 0 15px;
  }
  .form-acc label {
    margin: 15px 0 0px 0 !important;
  }
  .form-acc {
    text-align: left;
    /*max-width: 200px;*/
    padding: 0 40px;
    margin: auto;
    /*color: #ab7600;*/
    font-style: 
    italic;
  }
  .form-acc span{
    color: #ef001b;
  }

}
@media screen and (max-width: 767px){
  .content-detail-banking{
      /*background: #140700;*/
  }
    th.ant-table-cell {
        min-width: 60px;
    }
}
.content-deposit{
  /*background: #140700;*/
}
@media (max-width:767px) {
  .header-detail_mb h5{
    /*color: white;*/
  }
 .content-deposit .ant-form label{
    /*color: hsl(42deg 100% 50%);*/
    /*font-style: italic;*/
    font-weight: 600;
  }
  .form-deposit .ant-form-item{
    margin-bottom: 8px;
  }
  .input_block{
    background: #fff;
    color: #140601;
    line-height: inherit!important;
    padding: 4px 11px!important;

  }
}
.content-deposit .btn-bank-trans {
  padding: 10px;
  background: rgb(48, 46, 42);
  font-weight: 600;
  width: 100%;
  /*border-radius: 3px;*/
  font-size: 22px;
  color: #fff;
  text-align: center;
  /*font-style: italic;*/
}
#custom-primary-button-deposit{
  box-shadow: 0px 0px 6px rgb(254 206 49 / 20%);
    /*background: linear-gradient(180deg, rgba(200, 161, 137, 0.2) 0%, rgba(169, 128, 105, 0.2) 100%);*/
    border: 1px solid rgba(255, 214, 0, 0.2);
    margin-top: 20px!important;
    margin-bottom: 20px;
}
.form-deposit{
  padding: 10px 35px;
}
.input-deposit{
  margin-bottom: 10px;
}
.input-deposit input {
  width: 100%;
  padding: 6.5px 11px;;
  border-radius: 2px;
}
.input-deposit .tick{
  color: hsl(2deg 91% 60%);
}
.content-deposit .form-deposit .input-deposit  img{
  width: 50%;
}
.btn-upload button {
  background: hsl(0deg 100% 50%);
  border: none;
  width: 55%;
  color: #fff;
  padding: 5px;
  /*font-style: italic;*/
  cursor: pointer;
}
.btn-upload {
  text-align: right;
}
.form-deposit .form-check img {
  width: 25%;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.form-deposit .container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  user-select: none;
}
.checkmark:after {
  content: "";
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 15px;
  width: 15px;
  background-color: #eee;
}
.form-deposit .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.form-deposit .container:hover input ~ .checkmark {
  background-color: #ccc;
}
.channel-dep .input-channel-dep{
  display: flex;
}
.channel-dep .input-channel-dep .input-select-channel{
  width: 100%;
}
.channel-dep .input-channel-dep span{
  background: #fff;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  margin-left: 5px;
}

@media screen and (max-width: 767px){
  .content-deposit{
    /*background: #140700;*/
    /*height: 146vh;*/
    /*padding: 0 15px;*/
    /*overflow-y: scroll;*/
  }

  .content-deposit .btn-bank-trans {
    padding: 10px;
    background: #03607c;
    font-weight: 600;
    width: 100%;
    /*border-radius: 3px;*/
    font-size: 22px;
    /*color: #140700;*/
    text-align: center;
    /*font-style: italic;*/
  }
  .form-deposit{
    padding: 10px 35px;
  }
  .input-deposit input {
    width: 100%;
    margin-bottom: 5px;
    padding: 4px 11px;
    border-radius: 2px;
    border: none;
  }
  .btn-upload button {
    background: hsl(0deg 100% 50%);
    border: none;
    width: 55%;
    color: #fff;
    padding: 5px;
    /*font-style: italic;*/
  }
  .btn-upload {
    text-align: right;
  }
  .form-deposit .form-check img {
    width: 25%;
  }
  .form-deposit .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    user-select: none;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
  .checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #eee;
    transform: translateY(-50%);
  }
  .form-deposit .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .form-deposit .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

}
.input_block{
  background: #fff;
  border-radius: 2px;
  color: #140601;
  padding: 6.5px 11px;
}
.ant-input[disabled]{
  color: black!important;
}
input, select, optgroup, textarea{
  color: black!important;
}

.boxleft-wrapper {
  width: 40%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 20px 10px;
  background: #fff;
}

@media (max-width: 625px) {
  .boxleft-wrapper {
    padding: 20px 10px 0;
    width: 100%;
  }
}

.boxleft-style-user {
  display: flex;
  flex-direction: row;
  grid-gap: 15px;
  gap: 15px;
  padding-bottom: 30px;
}

@media (max-width: 625px) {
  .boxleft-style-user {
    width: 50%;
    align-items: flex-start;
    padding-bottom: 0;
  }
}

.boxleft-balance {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  padding: 30px 0;
}

@media (max-width: 625px) {
  .boxleft-balance {
    padding: 15px 0;
  }
}

.boxleft-userveri {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  padding: 30px 0;
}

@media (max-width: 625px) {
  .boxleft-userveri {
    display: none;
  }
}

.boxleft-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 20px;
  background: #f7f7f7;
}

.boxright-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 20px;
  background: #f7f7f7;
}

@media (max-width: 625px) {
  .boxleft-item {
    display: none;
  }
}

.boxleft-title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  margin: 0;
}

@media (max-width: 575px) {
  .boxleft-title {
    font-size: 15px;
  }
}

.boxleft-text {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #0071e3;
  margin: 0;
}

.boxleft-linewhite {
  border: 5px solid #fff;
  border-radius: 4px;
  margin: 10px 0;
}

@media (max-width:575px) {
  .boxleft-linewhite {
    display: none;
  }
}

.boxleft-line {
  border: 3px solid #fff;
  border-radius: 4px;
}

.boxleft-styleviri {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}


/* ===== SIDEBAR ===== */
.menu-boxleft-sidebar {
  /* width: 260px;
  background-color: #0d1b2a;
  padding: 20px; */
}

.menu-boxleft-profile {
  text-align: center;
  margin-bottom: 20px;
}

.menu-boxleft-profile img {
  width: 80px;
  border-radius: 50%;
}

.menu-boxleft-profile p {
  color: #a8dadc;
}

.menu-boxleft-menu {
  list-style: none;
  margin-top: 20px;
}

.menu-boxleft-menu li {
  padding: 12px;
  display: flex;
  align-items: center;
}

.menu-boxleft-menu li a {
  color: white;
  text-decoration: none;
  font-size: 16px;
  flex: 1 1;
}

.menu-boxleft-menu li.active {
  background-color: #f4a261;
  border-radius: 5px;
  padding-left: 10px;
}

.vip-badge-img {
  max-width: 120px;
  margin: -40px auto 10px;
  position: relative;
}

.mblvl-text-1 {
  color: #ffe27f;
  font-size: 16px;
}

.mblvl-text-2 {
  font-size: 26px;
  font-weight: 700;
  text-transform: uppercase;
}

.afmenu-box {
  background: #08202f;
  border-radius: 25px;
  padding: 25px;
}


.afmenu-box {
  background: #08202f;
  border-radius: 25px;
  padding: 25px;
}

ul.nav.afmenuside-nav {
  flex-direction: column;
}

ul.nav.afmenuside-nav li.nav-item {
  margin-bottom: 5px;
}

ul.nav.afmenuside-nav li.nav-item a.mslink {
  display: block;
  color: #fff;
  padding: 2px 10px;
  border-radius: 8px;
  font-size: 16px;
  height: 42px;
  display: flex;
  align-items: center;
  position: relative;
}

.afn-icon {
  margin-right: 15px;
}

.afn-icon img {
  max-height: 24px;
}

ul.nav.afmenuside-nav li.nav-item a.mslink.active,
ul.nav.afmenuside-nav li.nav-item a.mslink:hover {
  background: linear-gradient(to left, rgba(248, 236, 127, 1) 0%, rgba(201, 158, 66, 1) 100%);
  color: #0a1941;
  font-weight: 500;
}

ul.nav.afmenuside-nav li.nav-item a.mslink.active img,
ul.nav.afmenuside-nav li.nav-item a.mslink:hover img {
  filter: brightness(0) contrast(1);
}

a.mslink.expand-arrow::after {
  content: '\F107';
  font-family: 'Font Awesome 5 Free';
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  color: #194100;
  right: 15px;
  top: 14px;
  transform: rotate(180deg);
  transition: 0.3s;
}

a.mslink.expand-arrow.collapsed::after {
  content: '\F107';
  font-family: 'Font Awesome 5 Free';
  transform: none;
  transition: 0.3s;
}

.af-submenu-box {
  position: relative;
  padding: 0 5px 0 35px;
  margin-top: 5px;
}

.af-submenu-box::before {
  content: '';
  background: #78848b;
  height: 100%;
  width: 2px;
  margin: auto;
  position: absolute;
  left: 21px;
  top: 0;
}

.af-submenu-box a {
  display: block;
  color: #fff;
  margin: 3px 0;
  padding: 10px 15px;
  border-radius: 8px;
}

.af-submenu-box a:hover,
.af-submenu-box a.active {
  background: linear-gradient(to left, rgba(248, 236, 127, 1) 0%, rgba(201, 158, 66, 1) 100%);
  color: #0a1941;
}
.boxright-wrapper {
      width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 20px;
  background: #fff;
}
.boxright-title {
      font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  margin: 0;
}
.boxright-text {
      font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #8A9AA4;
}
.boxright-styled-text {
        display: flex;
    flex-direction: row;
}
.login-modal .ant-modal-content {
    background-color: #071629 !important;
    color: white;
    border-radius: 8px;
  }
  
  .ant-modal-title {
    display: flex;
    justify-self: center;
  }
  .modal-title {
    text-align: center;
    font-size: 24px;
    color: gold;
    width: 200px;
    height: 80px;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
  }
  
  .login-form .ant-input {
    background-color: #0b2e41 !important;
    color: white !important;
    border-radius: 5px;
    padding: 10px;
  }
  
  .captcha-section {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
  }

  .login-button {
    background: linear-gradient(to left, rgba(248, 236, 127, 1) 0%, rgba(201, 158, 66, 1) 100%) !important;
    border: none !important;
    border-radius: 8px !important;
    color: #0a1941 !important;
    font-size: 24px !important;
    font-weight: 700 !important;
    text-transform: uppercase !important;
    min-width: 220px !important;
    height: 44px !important;
    padding: 8px 15px !important;
    transition: 0.2s !important;
    cursor: pointer !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center;
  }
  
  .login-button:hover:enabled {
    background: linear-gradient(to right, rgba(248, 236, 127, 1) 0%, rgba(201, 158, 66, 1) 100%) !important;
    box-shadow: 0 0 10px -1px #ffed7d !important;
    transform: scale(0.98) !important;
    transition: 0.2s !important;
  }


  .login-button:disabled {
    color: rgba(0, 0, 0, 0.25) !important;
    border-color: #d9d9d9 !important;
    /* background: #f5f5f5 !important; */
    text-shadow: none !important;
    box-shadow: none !important;
    cursor: default !important;
  }
  
  .options {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }
  .ant-checkbox-wrapper {
    color: #ffffff !important;
  }
  .forgot-password {
    color: white;
    text-decoration: underline;
  }
  
  .register-text {
    text-align: center;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 600;
  }
  
  .error-text {
    text-align: left;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 600;
    color: red;
  }

  .highlight {
    color: gold;
    font-weight: bold;
  }
  
  .footer-text {
    text-align: center;
    font-size: 12px;
    color: #ccc;
    font-size: 14px;
    font-weight: 600;
  }
  
  .customer-service {
    color: gold;
    text-decoration: underline;
  }

  .ant-modal-header {
    background-color: #071629 !important;
    border-bottom: none !important
  }
  .ant-modal-close {
    color: #ffffff !important;
    font-size: 18px !important;
    font-weight: 700 !important;
  }
  .ant-input-affix-wrapper {
    background-color: #0b2e41 !important;
    color: white !important;
    border: none !important;
    line-height: 2 !important;
    border-radius: 12px !important;
  }
  .ant-input-password-icon.anticon {
    color: #ffffff !important;
  }
@media screen and (max-width: 767px) {
  .custom-mobile-deposit {
    height: 90%;
  }
}

.my-4 {
  margin-top: 4px;
  margin-bottom: 4px;
}

footer li {
  padding-right: 10px;
  margin: 5px 0;
  border-right: #606060 1px solid;
}

footer li:not(:first-child) {
  padding-left: 15px;
}

.game-slot-content {
  display: flex;
  justify-content: space-between;
  margin: 40px 120px 0px 120px;
  padding-bottom: 24px;
}

.game-slot-left {
  display: flex;
  justify-content: space-between;
}

.game-list-game {
  display: inline-grid;
}

.app-tabbar-item {
  width: 90%;
  height: 30px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  border: 5px solid #08202f;
  background: #08202f;
  color: #fff;
}

.app-tabBar-text {
  width: 100%;
  margin-left: 40px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #fff;
  margin: 0;
  display: flex;
  align-items: center;
}

footer li a {
  color: #606060;
}

.btm_box {
  padding: 0 10px 10px;
  font-weight: 500;
}

.btm_img_group {
  float: left;
}

.btm_img_group img {
  margin: 0.5rem 0.4rem 0.5rem 0;
  max-width: 28%;
}

.copyright {
  display: flex;
  justify-content: center;
}

.contact_items {
  background: url(/img/itembg4.jpg) top center no-repeat;
  background-size: 100% 100%;
  display: flex;
}

.contact_name {
  flex: 0 1 10%;
  background: url(/img/itembg5.png) top center no-repeat;
  background-size: 100% 100%;
  color: #fff;
  min-width: 80px;
  padding: 5px 10px 0 10px;
}

.itemsbox_group {
  white-space: nowrap;
  overflow: scroll;
}

.contact_box {
  color: #14805e;
  display: inline-block;
  max-width: 100px;
  padding: 15px 10px 10px 10px;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
}

.contact_box a,
.contact_box a:hover {
  color: #14805e;
}

.itemsbox_group a {
  display: inline-block;
  margin-right: 1rem;
}

.contact_box img {
  max-width: 50px;
}

.game-container {
  width: 100%;
  display: flex;
  overflow-x: auto;
}

.game-container .game-ele {
  color: #fff;
  display: flex;
  font-size: 16px;
  text-align: center;
  padding-top: 1rem;
  align-items: center;
  flex-direction: column;
  margin-right: 15px;
  max-width: 100px;
  min-width: 100px;
}

.w_1220 .main {
  width: 100%;
}

.w_1220 .main section {
  padding: 30px 0;
  margin: 50px auto;
}

.w_1220 .main .content-1 {
  display: flex;
}

.w_1220 .main .content-5 {
  display: flex;
}

.w_1220 .main .content-1 .content-1-left .title-play {
  font-size: 38px;
  font-weight: bold;
  color: hsl(0deg 100% 50%);
}

.w_1220 .main .contentst2 {
  display: flex;
  background: hsl(217deg 100% 17%);
  justify-content: space-between;
  border-radius: 25px;
  border: 2px solid hsl(250deg 83% 62%);
  padding: 10px 30px;
}

.w_1220 .main .content-2 .content-2-col {
  display: flex;
  align-items: center;
}

.w_1220 .main .content-2 .content-2-col .text {
  padding-left: 10px;
}

.w_1220 .main .content-2 .content-2-col .text h3 {
  height: 73px;
}

.w_1220 .main .content-2 .content-2-col .text p {
  font-size: 15px;
  color: hsl(29deg 99% 50%);
  margin: 0;
}

.w_1220 .main .content-3 {
  padding-bottom: 0;
  text-align: center;
}

.w_1220 .main .content-4 {
  padding-top: 0;
}

.w_1220 .main .content-3 .content-3-text {
  max-width: 500px;
  margin: 10px auto;
  text-align: center;
}

.w_1220 .main .content-3 .content-3-logo {
  display: flex;
}

.content-3 .text-title-lottery {
  text-align: center;
  max-width: 500px;
  margin: auto;
}

.content-3 .text-title-lottery h4 {
  color: red;
}

.content-3 .text-title-lottery h3 {
  font-size: 48px;
}

.w_1220 .main .content-3 .content-3-lotte .title-lotte {
  text-align: center;
}

.content-3-logo-col {
  flex-basis: 20%;
  max-width: 20%;
  padding: 0 15px;
}

.w_1220 .main .content-3 .lst-img .content-logo-col {
  padding: 0 20px;
  display: grid;
}

.w_1220 .main .content-3 .lst-img .content-logo-col img {
  border-radius: 10px;
  width: 103px;
}

.content-3-logo-col img {
  width: 100%;
}

.btn-play-now {
  text-align: center;
  margin-top: 15px;
  align-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.w_1220 .main .content-4 {
  width: 65%;
  margin: auto;
  text-align: center;
}

.w_1220 .main .content-4 .content-4-slot-game {
  margin: 20px 0;
}

.w_1220 .main .lst-img {
  display: contents;
  margin: 30px 0px;
}

.w_1220 .main .content-4 .lst-img .content-logo-col {
  flex: 1 1;
  padding: 0 20px;
  max-width: 143px;
}

.w_1220 .main .content-4 .lst-img .content-logo-col .frame-img {
  padding: 15px;
  border: 3px solid hsl(251deg 92% 64%);
  border-radius: 10px;
}

.w_1220 .main .content-4 .lst-img img {
  width: 103px;
}

.w_1220 .main .content-5 .content-5-left {
  display: flex;
  flex-basis: 60%;
}

.w_1220 .main .content-5 .content-5-left p {
  font-weight: 700;
  font-size: 20px;
}

/* common */
.btn-red {
  flex-shrink: 0;
  height: 35px;
  background: hsl(359deg 95% 42%);
  font-weight: 500;
  box-shadow: 0 3px 5px 0 rgba(26, 26, 26, 0.23);
  border-radius: 8px;
  border-width: 0;
  color: #fff;
  align-items: center !important;
  justify-content: space-around !important;
  display: flex !important;
  font-size: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}

.btn-start .button .text {
  color: white;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.content-left {
  flex-basis: 50%;
  padding-left: 11px;
}

.content-right {
  flex-basis: 50%;
  max-width: 50%;
}

.content-5-right {
  flex-basis: 40% !important;
}

.btn-gr-menu a {
  color: #fff;
}

.btn-gr-menu a:hover {
  color: hsl(359deg 95% 42%);
}

.refreshWallet {
  border-radius: 15px !important;
  line-height: 10px;
  height: 20px;
}

@media screen and (max-width: 767px) {
  .content-lst-game .icon-img {
    text-align: center;
  }

  .content-lst-game .icon-img img {
    width: 50px;
  }

  h6 {
    font-size: 14px;
  }

  .lst-game {
    width: 100vw;
  }

  .content-lst-game {
    display: flex;
  }

  .content-lst-game h6 {
    margin: 0;
  }

  .content-2-col {
    flex: 1 1;
    padding: 5px 1px 5px 1px;
    border: solid 1px #fdfdfd;
    border-radius: 5px;
    background: #f1f1f1;
    font-weight: bold;
  }

  .content-3-lotte {
    display: flex;
    flex-wrap: wrap;
  }

  .content-3-lotte .content-3-logo-col {
    flex-basis: 33.3%;
    max-width: 33.3%;
    padding: 10px;
  }

  .content-text {
    max-width: 296px;
    margin: 25px auto;
    text-align: left;
    font-size: 14px;
  }

  .menu_mb {
    position: relative;
    background-color: #ffe27f !important;
  }

  .lst-wallet {
    display: flex;
    align-items: center;
    background: #03607c;
    padding: 10px 0;
    text-align: center;
    color: #fff;
  }

  .lst-wallet .load-wallet {
    flex-basis: 36%;
    border-right: 1px solid #80808087;
  }

  .lst-wallet .load-wallet .wallet-top {
    justify-items: center;
    align-items: center;
  }

  .lst-wallet img {
    width: 32%;
  }

  .lst-wallet .scale-img {
    width: 50%;
  }

  .lst-wallet p {
    font-weight: 600;
    font-size: 12px;
    margin: 0;
  }

  .wallet-top .content-text-wallet {
    flex-basis: 40%;
    text-align: left;
  }

  .item-wallet {
    flex-basis: 20%;
  }

  .wallet-bottom {
    padding: 0 1px;
  }

  .wallet-bottom button {
    border-radius: 10px;
    color: #fff;
    background: #000547;
    border: 1px solid #fff;
    font-weight: 600;
    width: 100%;
  }

  .lst-img {
    flex-wrap: wrap;
    justify-content: center;
  }

  .lst-img .content-logo-col {
    flex-basis: calc(100% / 3);
    padding: 10px;
  }
}

/* How To Register */
.howto {
  max-width: 1224px;
  width: 100%;
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ::-webkit-scrollbar {
    width: 5px;
    height: 3px;
  }

  @media (max-width: 575px) {
    margin: 0 0 30px;
  }
}

.howto-register {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1170px) {
  .howto-register {
    width: 990px;
    margin: 0 auto;
  }
}

.howto-text {
  width: 100%;
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #000;
}

@media (max-width: 575px) {
  .howto-text {
    font-size: 16px;
    line-height: 24px;
  }
}

.howto-title {
  width: 100%;
  font-size: 32px;
  line-height: 42px;
  font-weight: 600;
  color: #000;
}

@media (max-width: 575px) {
  .howto-title {
    font-size: 24px;
    line-height: 32px;
  }
}

.howto-list-register {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
}

@media (max-width: 1170px) {
  .howto-list-register {
    width: 990px;
    justify-content: center;
  }
}

.howto-card-text {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  text-align: center;
  margin: 0;
  color: #000;
}

@media (max-width: 575px) {
  .howto-card-text {
    font-size: 14px;
    line-height: 18px;
  }
}

.howto-styled-img {
  padding: 30px 50px;
}

@media (max-width: 575px) {
  .howto-styled-img {
    width: 95%;
    padding: 9px 0px 6px 50px;
  }
}

.howto-style-imgcard {
  width: 100px;
  height: 100px;
  margin-bottom: 5px;
}

@media (max-width: 575px) {
  .howto-style-imgcard {
    width: 80px;
    height: 80px;
  }
}

.theme-box1 {
  width: 280px;
  height: 200px;
  padding: 10px 20px 20px;
  background: #fbe6e6;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 575px) {
  .theme-box1 {
    width: 240px;
    height: 150px;
  }
}

.theme-box2 {
  width: 280px;
  height: 200px;
  padding: 10px 20px 20px;
  background: #edfcf4;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 575px) {
  .theme-box2 {
    width: 240px;
    height: 150px;
  }
}

.theme-box3 {
  width: 280px;
  height: 200px;
  padding: 10px 20px 20px;
  background: #fbf4e6;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 575px) {
  .theme-box3 {
    width: 240px;
    height: 150px;
  }
}

hr {
  margin: 50px 0;
}

.thumbnail-wrapper {
  margin-top: -350px;
  height: 85px;
  width: 90%;
  margin-left: 60px;
}

.thumbnail-wrapper .slick-track .slick-slide {
  text-align: center;
}

.thumbnail-wrapper .slick-track .slick-slide img {
  display: flex;
  margin: 0px auto;
  height: 5vh;
  width: 30%;
  border-radius: 5%;
  cursor: pointer;
}

/* Colection */
.colection-slide {
  width: 100%;
  margin: 30px 0;
}

@media (max-width: 575px) {
  .colection-slide {
    margin: 20px 0;
  }
}

@media (max-width: 480px) {
  .colection-slide {
    padding: 0 14px;
  }
}

.colection-tilte {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

@media (max-width: 575px) {
  .colection-tilte {
    padding: 5px 0;
  }
}

.colection-slide-text-title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: #000;
  margin: 0;
}

@media (max-width: 575px) {
  .colection-slide-text-title {
    font-size: 16px;
    line-height: 24px;
  }
}

.colection-text-more {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-decoration: underline;
  color: #000;
  cursor: pointer;
  margin: 0;
}

@media (max-width: 575px) {
  .colection-text-more {
    font-size: 14px;
    line-height: 24px;
  }
}

.colection-text-more:hover {
  color: #ff8100;
}

.colection-slide-show {
  position: relative;
  max-width: 100%;
}

@media (max-width: 1300px) {
  .colection-slide-show {
    overflow: hidden auto;
  }
}

.colection-head-slide-show {
  position: relative;
  max-width: 100%;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.slick-slide {
  cursor: pointer;
  padding: 0 10px;
}

@media (max-width: 1200px) {
  .slick-slide {
    padding: 0 9px;
  }
}

.colection-img-slide {
  max-width: 280px;
}

@media (max-width: 480px) {
  .colection-img-slide {
    max-width: 100%;
  }
}

/* App Downdow */
.app-download {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 100px 0 50px;
  grid-gap: 20px;
  gap: 20px;
}

@media (max-width: 925px) {
  .app-download {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 575px) {
  .app-download {
    margin: 50px 0;
  }
}

.app-download-left {
  width: 40%;
}

@media (max-width: 925px) {
  .app-download-left {
    width: 100%;
  }
}

.app-download-left-text {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: #000;
}

@media (max-width: 575px) {
  .app-download-left-text {
    font-size: 18px;
    line-height: 24px;
  }
}

.app-download-left-title {
  max-width: 460px;
  font-size: 32px;
  line-height: 42px;
  font-weight: 600;
  color: #000;
}

@media (max-width: 575px) {
  .app-download-left-title {
    max-width: 375px;
    font-size: 24px;
    line-height: 32px;
  }
}

.app-download-left-detail {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #7f919e;
  padding-bottom: 10px;
}

@media (max-width: 575px) {
  .app-download-left-detail {
    font-size: 13px;
    line-height: 18px;
  }
}

.app-download-groupqr {
  display: flex;
  flex-direction: row;
  width: 100%;
  grid-gap: 20px;
  gap: 20px;
}

@media (max-width: 375px) {
  .app-download-groupqr {
    grid-gap: 10px;
    gap: 10px;
  }
}

.app-download-styledqr-dowload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  grid-gap: 20px;
  gap: 20px;
}

@media (max-width: 370px) {
  .app-download-styledqr-dowload {
    width: 130px;
  }
}

.app-download-appapple {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  border-radius: 24px;
  border: 1px solid #ccc;
  padding: 5px 5px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #000;
}

.app-download-appIntrstions {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: #1358c7;
  cursor: pointer;
}

@media (max-width: 575px) {
  .app-download-appIntrstions {
    font-size: 14px;
    line-height: 18px;
  }
}

.app-download-right {
  width: 60%;
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
}

@media (max-width: 925px) {
  .app-download-right {
    width: 100%;
  }
}

.app-download-right-left {
  width: 45%;
}

@media (max-width: 575px) {
  .app-download-right-left {
    /* width: 100%; */
    display: none;
  }
}

.app-download-right-right {
  width: 55%;
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
}

@media (max-width: 575px) {
  .app-download-right-right {
    width: 100%;
  }
}

.app-download-right-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.featured-slots-body {
  background-color: #0d1b2a;
  color: white;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0px 0px 20px 0px;
  border-bottom: 3px solid rgba(255, 215, 0, 0.5);
}

.featured-slots-container {
  width: 90%;
  max-width: 1200px;
  margin: auto;
  padding: 20px 20px 20px 0px;
}

/* HEADER */
.featured-slots-header {
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  /* border-left: 4px solid gold; */
  margin-bottom: 15px;
}

/* .featured-slots-header img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
} */

/* TAB CONTAINER */
.featured-slots-tab-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: 3px solid rgba(255, 215, 0, 0.5);
  /* Line vàng mờ */
}

.featured-slots-tab-buttons {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  position: relative;
}

.featured-slots-tab-button {
  background: linear-gradient(to right, rgba(6, 32, 51, 1) 0%, rgba(4, 23, 38, 1) 100%);
  border: 1px solid #ffe27f;
  border-bottom: none;
  color: #ffe27f;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 8px 8px 0 0;
  min-width: 150px;
  text-align: center;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
}

.featured-slots-tab-button.active {
  background: linear-gradient(to left, rgba(248, 236, 127, 1) 0%, rgba(201, 158, 66, 1) 100%);
  color: #0a1941;
  border: 1px solid transparent;
}

.featured-slots-tab-button.active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 100%;
  height: 3px;
  background-color: gold;
}

.featured-slots-more-games-btn {
  background: linear-gradient(to left, rgba(248,236,127,1) 0%, rgba(201,158,66,1) 100%);
  border: none;
  color: #0a1941;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 15px;
  padding: 5px 15px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.featured-slots-more-games-btn:hover {
  filter: brightness(1.05);
  transform: scale(0.98);
}

/* GAME GRID */
.featured-slots-game-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 10px;
  gap: 10px;
  justify-items: center;
}

.featured-slots-more-game-grid {
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
  display: flex;
}
.featured-slots-more-game-mgbox {
  display: inline-block;
  position: relative;
  max-width: 290px;
  margin: 0 10px;
  overflow: hidden;
  cursor: pointer;
}
.featured-slots-more-game-mgimg-hover {
	opacity: 0;
	visibility: hidden;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	transition: 0.2s;
	transform: translateY(90px);
}
.featured-slots-more-game-mgbox:hover .featured-slots-more-game-mgimg-hover {
	opacity: 1;
	visibility: visible;
	transition: 0.2s;
	transform: none;
}

.featured-slots-more-game-item {
  width: 220px;
  height: 150px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  margin: 0px 20px;
}

.featured-slots-more-game-item-hover {
  /* transition: 0.2s; */
  transform: translateY(90px);
  height: 150px;
}

.featured-slots-more-game-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.5s ease-in-out;
}

/* Hiệu ứng hover đổi hình ảnh */
.featured-slots-more-game-item:hover img.default {
  opacity: 0;
}

.featured-slots-more-game-item img.hover {
  position: absolute;
  opacity: 0;
}

.featured-slots-more-game-item:hover img.hover {
  opacity: 1;
}

.featured-slots-game-item {
  width: 150px;
  height: 100px;
  background-color: #1b2a41;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s;
  position: relative;
}

.featured-slots-game-item:hover {
  transform: scale(1.05);
}

.featured-slots-game-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.5s ease-in-out;
}

/* Hiệu ứng hover đổi hình ảnh */
.featured-slots-game-item:hover img.default {
  opacity: 0;
}

.featured-slots-game-item img.hover {
  position: absolute;
  opacity: 0;
}

.featured-slots-game-item:hover img.hover {
  opacity: 1;
}

/* TAB CONTENT */
.featured-slots-tab-content {
  display: none;
}

.featured-slots-tab-content.active {
  display: block;
}

/* SEE MORE BUTTON */
.featured-slots-see-more {
  background-color: gold;
  color: black;
  padding: 4px 8px;
  border-radius: 5px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  width: max-content;
  cursor: pointer;
  transition: 0.3s;
  border: none;
}

.featured-slots-see-more:hover {
  background-color: darkgoldenrod;
}

/* PLAY NOW BUTTON */
.featured-slots-play-now {
  background-color: gold;
  color: black;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: max-content;
  cursor: pointer;
  transition: 0.3s;
  border: none;
}

.featured-slots-play-now:hover {
  background-color: darkgoldenrod;
}

.games-button {
  display: flex;
  align-items: center;
  background: linear-gradient(to right, #0d1b2a, #1b2a41); /* Hiệu ứng gradient */
  padding: 12px 25px;
  border-radius: 5px;
  color: white;
  font-family: Arial, sans-serif;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  box-shadow: inset -2px -2px 5px rgba(255, 255, 255, 0.1), inset 2px 2px 5px rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
  transition: 0.3s ease-in-out;
}

/* Thanh vàng bên trái với góc cắt */
.games-button::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 6px;
  height: 100%;
  background: gold;
  clip-path: polygon(0 0, 100% 20%, 100% 80%, 0 100%);
}

.games-button:hover {
  background: linear-gradient(to right, #1b2a41, #0d1b2a);
  transform: scale(1.05);
}

.games-button img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.fs-14 {
  font-size: 14px;
}
.w-24 {
  width: 24px !important;
}

.h-24 {
  height: 24px !important;
}
.w-15 {
  width: 15px !important;
}

.h-15 {
  height: 15px !important;
}

.b-none {
  border: none !important;
}

.game-description-body {
  background-color: #0d1b2a;
  color: white;
  font-family: Arial, sans-serif;
  border-top: 3px solid #ffe27f;
  margin: 0;
  padding: 0px 0px 20px 0px;
  font-size: 14px;
  font-weight: 700;
}

.game-description-container {
  padding: 10px 30px
}

.footer-body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #0d1b2a;
  color: #ffffff;
}

.footer {
  background-color: #081122;
  padding: 30px 40px 0px 40px;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px dashed #ffffff;
  border-bottom: 1px dashed #ffffff;
  padding: 20px 0;
}

.footer-section {
  width: 30%;
  text-align: left;
}

.footer-h4 {
  font-size: 14px;
  margin-bottom: 10px;
  color: #545d69;
  text-align: left;
}

.footer-logos, .footer-logos-small, .footer-payment-icons, .footer-social-icons, .footer-providers {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
  justify-content: flex-start;
}

.footer-logos-small {
  height: 40px !important;
  max-width: 70px !important;
}

.footer-logos-medium {
  height: 40px !important;
  max-width: 130px !important;
}

.footer-logos-large {
  height: 40px !important;
  max-width: 150px !important;
}

.footer-social-icons img {
  height: 40px;
  max-width: 40px;
}
.footer-logos img, .footer-payment-icons img, .footer-providers img {
  height: 40px;
  max-width: 100px;
}

/* Phần Border Left - Right Chỉ Ở Giữa */
.footer-middle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.footer-border-left, .footer-border-right {
  width: 2px;
  height: 130px;
  border-left: 1px dashed #ffffff;
}

.footer-border-content {
  padding: 20px;
  text-align: center;
}

.footer-providers {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 15px;
  gap: 15px;
  justify-content: left;
}

.footer-providers img {
  height: 40px;
  max-width: 90px;
}
.ml-i-5 {
  margin-left: -5px;
}
.ml-i-20 {
  margin-left: -20px;
}

.footer-copy-right {
  border-top: 1px dashed #ffffff;
  padding: 20px 0;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
}


.howto-tab-content {
  position: relative;
  margin-top: -40px;
  margin-right: -80px;
}
/* 
.howto-tab-content>.howto-tab-pane {
  display: none;
} */
.howto-fade:not(.show) {
  opacity: 0;
}
.howto-fade {
  transition: opacity .15s linear;
}
.howto-tab-content {
  > .howto-tab-pane {
    display: none;
  }
  > .active {
    display: block;
  }
}
.howto-sports-intro-wrap {
  width: 690px;
  margin: 0 auto;
}
.howto-sg-caption {
  border-bottom: 4px solid #d6b987;
  background: rgb(11, 41, 63, 0.8);
  color: #fff;
  font-size: 17px;
  padding: 15px 20px;
  position: absolute;
  max-width: 231px;
  top: 35%;
}
.howto-sg-chrt-img {
  text-align: right;
  position: relative;
  max-width: 70%;
  margin: 0 0 0 auto;
}
.howto-sg-text {
  max-width: 74%;
  margin-top: 12px;
}

.featured-slots-title-wrap {
  position: relative;
  margin: 0 0 20px;
}
.featured-slots-title-text {
  position: absolute;
  color: #fff;
  font-weight: 800;
  font-size: 19px;
  text-transform: uppercase;
  left: 30px;
  top: 7px;
  display: flex;
  align-items: center;
}
.featured-slots-title-text img {
  height: 28px;
  width: 28px;
  margin-right: 15px;
}

.howto-app-tabBar-text {
  color: #ffffff
}
.form-label-control span {
  color: #ffaf50 !important
}
.account_wrap {
  min-height: 100%;
}
.custom-label-account > .ant-form-item-label > label{
  color: white;
  font-weight: 700;
}
.profile_top {
  background: url(/img/profile.jpg) top center no-repeat;
  background-size: 100% 100%;
  border-bottom: 1px solid #f3b72f;
  color: #fff;
  padding: 0.85rem 0;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 56px
}
.mainwallet_box {
  display: block;
  flex-direction: column;
  align-items: center;
  background: #044531;
  color: #fff;
  padding: 0.5rem 0;
}

.pro_title {
  color: #ffaf50;
}
.num {
  font-size: 20px;
}

.pro_wrap {
  float: left;
  width: 100%;
  margin-bottom: 55px;
  background: #07593f;
}

.pro_box:first-child {
  border-left: none;
}

.pro_box {
  background: #022f21;
  width: 50%;
  float: left;
  text-align: center;
  border-right: 1px solid #07593f;
  padding: 1rem 0;
  margin: 0 0 1px;
}

.clearfix {
  clear: both;
}

.wallBx {
  display: inline-block;
  text-align: center;
  min-width: 130px;
  margin: 0.4rem 0.2rem;
  width: 47%;
}

.wallTxt {
  background: #14805e;
  color: #fff;
  padding: 4px 0;
}

.wallBx > .wMain, .wallBx > .wTotal {
  background: #eec67d;
  background: -moz-linear-gradient(top, #eec67d, #f6b52a);
  filter: progid: DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#eec67d', endColorstr='#f6b52a');
  background: linear-gradient(to bottom, #eec67d, #f6b52a);
  color: #000;
}
.App {
  text-align: center;
}
body{
  /*color: white!important;*/
}
.c-pointer {
 cursor: pointer;
}

.hide {
  display: none
}

/*.globalClass_ET > jdiv {*/
  /*bottom: 70px !important*/
/*}*/

.label_39._bottom_3v._pad100_GR {
  bottom: 70px !important
}

.link-btn-default {
  padding: 8px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  line-height: 1.75;
  font-weight: 500;
  font-family: "Source Sans Pro","Helvetica Neue",Helvetica,Arial,sans-serif;
  border-radius: 4px;
  text-transform: uppercase;
  margin-top: 24px;
  font-weight: bold;
  color: #fff !important;
  background-color: #14805E;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.table-wrapper {
  overflow-x: auto
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.play-game {
  width: 100%;
  height: 100%
}

.play-game iframe {
  width: 100%;
  height: 100%;
  border: none
}

.__jivoMobileButton {
  /*position: absolute !important;*/
  /*width: 40px !important;*/
  /*height: 40px !important;*/
  /*top: 10px !important;*/
  /*right: 10px !important;*/
  bottom: 30px !important;
}
.button_1O{
  width: 40px !important;
  height: 40px !important;
  margin-bottom: 0 !important;
}
.popupBlock_1N {
  display: none !important;
}
.select-language {
  color: white !important;
}
.select-language:hover {
  color: black !important;
}
.ant-col.ant-form-item-label {
  padding: 0;
}
form.ant-form.ant-form-horizontal.form-deposit {
  padding-bottom: 30px;
  background: #fff;
}
.label-with-draw label{
  font-weight: 700;
  /*color: hsl(42deg 100% 50%)!important;*/
  
}
.label-with-draw{
  margin-bottom: 10px!important;
}
.label-with-draw  .ant-form-item-label{
  width: 43%;
  text-align: left;
  white-space: pre-wrap;
  padding: 0;
}
td.select-date{
  max-width: 20%;
}
@media screen and (max-width: 767px){
}
.react-datepicker-ignore-onclickoutside{
  color: black;
}
.react-datepicker__input-container input{
  color: black;
}
.reportTable th, .reportTable td{
  color: black!important;
}

.content-deposit{
  /*background: #140700;*/
}
@media (max-width:767px) {
  .header-detail_mb h5{
    /*color: white;*/
  }
 .content-deposit .ant-form label{
    /*color: hsl(42deg 100% 50%);*/
    /*font-style: italic;*/
    font-weight: 600;
  }
  .form-deposit .ant-form-item{
    margin-bottom: 8px;
  }
  .input_block{
    background: #fff;
    color: #140601;
    line-height: inherit!important;
    padding: 4px 11px!important;

  }
}
.content-deposit .btn-bank-trans {
  padding: 10px;
  background: rgb(48, 46, 42);
  font-weight: 600;
  width: 100%;
  /*border-radius: 3px;*/
  font-size: 22px;
  color: #fff;
  text-align: center;
  /*font-style: italic;*/
}
#custom-primary-button-deposit{
  box-shadow: 0px 0px 6px rgb(254 206 49 / 20%);
    /*background: linear-gradient(180deg, rgba(200, 161, 137, 0.2) 0%, rgba(169, 128, 105, 0.2) 100%);*/
    border: 1px solid rgba(255, 214, 0, 0.2);
    margin-top: 20px!important;
    margin-bottom: 20px;
}
.form-deposit{
  padding: 10px 35px;
}
.input-deposit{
  margin-bottom: 10px;
}
.input-deposit input {
  width: 100%;
  padding: 6.5px 11px;;
  border-radius: 2px;
}
.input-deposit .tick{
  color: hsl(2deg 91% 60%);
}
.content-deposit .form-deposit .input-deposit  img{
  width: 50%;
}
.btn-upload button {
  background: hsl(0deg 100% 50%);
  border: none;
  width: 55%;
  color: #fff;
  padding: 5px;
  /*font-style: italic;*/
  cursor: pointer;
}
.btn-upload {
  text-align: right;
}
.form-deposit .form-check img {
  width: 25%;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.form-deposit .container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  user-select: none;
}
.checkmark:after {
  content: "";
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 15px;
  width: 15px;
  background-color: #eee;
}
.form-deposit .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.form-deposit .container:hover input ~ .checkmark {
  background-color: #ccc;
}
.channel-dep .input-channel-dep{
  display: flex;
}
.channel-dep .input-channel-dep .input-select-channel{
  width: 100%;
}
.channel-dep .input-channel-dep span{
  background: #fff;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  margin-left: 5px;
}

@media screen and (max-width: 767px){
  .content-deposit{
    /*background: #140700;*/
    /*height: 146vh;*/
    /*padding: 0 15px;*/
    /*overflow-y: scroll;*/
  }

  .content-deposit .btn-bank-trans {
    padding: 10px;
    background: #03607c;
    font-weight: 600;
    width: 100%;
    /*border-radius: 3px;*/
    font-size: 22px;
    /*color: #140700;*/
    text-align: center;
    /*font-style: italic;*/
  }
  .form-deposit{
    padding: 10px 35px;
  }
  .input-deposit input {
    width: 100%;
    margin-bottom: 5px;
    padding: 4px 11px;
    border-radius: 2px;
    border: none;
  }
  .btn-upload button {
    background: hsl(0deg 100% 50%);
    border: none;
    width: 55%;
    color: #fff;
    padding: 5px;
    /*font-style: italic;*/
  }
  .btn-upload {
    text-align: right;
  }
  .form-deposit .form-check img {
    width: 25%;
  }
  .form-deposit .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    user-select: none;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
  .checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #eee;
    transform: translateY(-50%);
  }
  .form-deposit .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .form-deposit .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

}
.input_block{
  background: #fff;
  border-radius: 2px;
  color: #140601;
  padding: 6.5px 11px;
}
.ant-input[disabled]{
  color: black!important;
}
input, select, optgroup, textarea{
  color: black!important;
}

td.select-date{
  max-width: 20%;
}
@media screen and (max-width: 767px){
}
.react-datepicker-ignore-onclickoutside{
  color: black;
}
.react-datepicker__input-container input{
  color: black;
}
.reportTable th, .reportTable td{
  color: black!important;
}

