.content-deposit{
  /*background: #140700;*/
}
@media (max-width:767px) {
  .header-detail_mb h5{
    /*color: white;*/
  }
 .content-deposit .ant-form label{
    /*color: hsl(42deg 100% 50%);*/
    /*font-style: italic;*/
    font-weight: 600;
  }
  .form-deposit .ant-form-item{
    margin-bottom: 8px;
  }
  .input_block{
    background: #fff;
    color: #140601;
    line-height: inherit!important;
    padding: 4px 11px!important;

  }
}
.content-deposit .btn-bank-trans {
  padding: 10px;
  background: rgb(48, 46, 42);
  font-weight: 600;
  width: 100%;
  /*border-radius: 3px;*/
  font-size: 22px;
  color: #fff;
  text-align: center;
  /*font-style: italic;*/
}
#custom-primary-button-deposit{
  box-shadow: 0px 0px 6px rgb(254 206 49 / 20%);
    /*background: linear-gradient(180deg, rgba(200, 161, 137, 0.2) 0%, rgba(169, 128, 105, 0.2) 100%);*/
    border: 1px solid rgba(255, 214, 0, 0.2);
    margin-top: 20px!important;
    margin-bottom: 20px;
}
.form-deposit{
  padding: 10px 35px;
}
.input-deposit{
  margin-bottom: 10px;
}
.input-deposit input {
  width: 100%;
  padding: 6.5px 11px;;
  border-radius: 2px;
}
.input-deposit .tick{
  color: hsl(2deg 91% 60%);
}
.content-deposit .form-deposit .input-deposit  img{
  width: 50%;
}
.btn-upload button {
  background: hsl(0deg 100% 50%);
  border: none;
  width: 55%;
  color: #fff;
  padding: 5px;
  /*font-style: italic;*/
  cursor: pointer;
}
.btn-upload {
  text-align: right;
}
.form-deposit .form-check img {
  width: 25%;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.form-deposit .container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkmark:after {
  content: "";
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 15px;
  width: 15px;
  background-color: #eee;
}
.form-deposit .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.form-deposit .container:hover input ~ .checkmark {
  background-color: #ccc;
}
.channel-dep .input-channel-dep{
  display: flex;
}
.channel-dep .input-channel-dep .input-select-channel{
  width: 100%;
}
.channel-dep .input-channel-dep span{
  background: #fff;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  margin-left: 5px;
}

@media screen and (max-width: 767px){
  .content-deposit{
    /*background: #140700;*/
    /*height: 146vh;*/
    /*padding: 0 15px;*/
    /*overflow-y: scroll;*/
  }

  .content-deposit .btn-bank-trans {
    padding: 10px;
    background: #03607c;
    font-weight: 600;
    width: 100%;
    /*border-radius: 3px;*/
    font-size: 22px;
    /*color: #140700;*/
    text-align: center;
    /*font-style: italic;*/
  }
  .form-deposit{
    padding: 10px 35px;
  }
  .input-deposit input {
    width: 100%;
    margin-bottom: 5px;
    padding: 4px 11px;
    border-radius: 2px;
    border: none;
  }
  .btn-upload button {
    background: hsl(0deg 100% 50%);
    border: none;
    width: 55%;
    color: #fff;
    padding: 5px;
    /*font-style: italic;*/
  }
  .btn-upload {
    text-align: right;
  }
  .form-deposit .form-check img {
    width: 25%;
  }
  .form-deposit .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #eee;
    transform: translateY(-50%);
  }
  .form-deposit .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .form-deposit .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

}
.input_block{
  background: #fff;
  border-radius: 2px;
  color: #140601;
  padding: 6.5px 11px;
}
.ant-input[disabled]{
  color: black!important;
}
input, select, optgroup, textarea{
  color: black!important;
}
