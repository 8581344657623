td.select-date{
  max-width: 20%;
}
@media screen and (max-width: 767px){
}
.react-datepicker-ignore-onclickoutside{
  color: black;
}
.react-datepicker__input-container input{
  color: black;
}
.reportTable th, .reportTable td{
  color: black!important;
}
