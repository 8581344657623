.login-modal .ant-modal-content {
    background-color: #071629 !important;
    color: white;
    border-radius: 8px;
  }
  
  .ant-modal-title {
    display: flex;
    justify-self: center;
  }
  .modal-title {
    text-align: center;
    font-size: 24px;
    color: gold;
    width: 200px;
    height: 80px;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .login-form .ant-input {
    background-color: #0b2e41 !important;
    color: white !important;
    border-radius: 5px;
    padding: 10px;
  }
  
  .captcha-section {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .login-button {
    background: linear-gradient(to left, rgba(248, 236, 127, 1) 0%, rgba(201, 158, 66, 1) 100%) !important;
    border: none !important;
    border-radius: 8px !important;
    color: #0a1941 !important;
    font-size: 24px !important;
    font-weight: 700 !important;
    text-transform: uppercase !important;
    min-width: 220px !important;
    height: 44px !important;
    padding: 8px 15px !important;
    transition: 0.2s !important;
    cursor: pointer !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center;
  }
  
  .login-button:hover:enabled {
    background: linear-gradient(to right, rgba(248, 236, 127, 1) 0%, rgba(201, 158, 66, 1) 100%) !important;
    box-shadow: 0 0 10px -1px #ffed7d !important;
    transform: scale(0.98) !important;
    transition: 0.2s !important;
  }


  .login-button:disabled {
    color: rgba(0, 0, 0, 0.25) !important;
    border-color: #d9d9d9 !important;
    /* background: #f5f5f5 !important; */
    text-shadow: none !important;
    box-shadow: none !important;
    cursor: default !important;
  }
  
  .options {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }
  .ant-checkbox-wrapper {
    color: #ffffff !important;
  }
  .forgot-password {
    color: white;
    text-decoration: underline;
  }
  
  .register-text {
    text-align: center;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 600;
  }
  
  .error-text {
    text-align: left;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 600;
    color: red;
  }

  .highlight {
    color: gold;
    font-weight: bold;
  }
  
  .footer-text {
    text-align: center;
    font-size: 12px;
    color: #ccc;
    font-size: 14px;
    font-weight: 600;
  }
  
  .customer-service {
    color: gold;
    text-decoration: underline;
  }

  .ant-modal-header {
    background-color: #071629 !important;
    border-bottom: none !important
  }
  .ant-modal-close {
    color: #ffffff !important;
    font-size: 18px !important;
    font-weight: 700 !important;
  }
  .ant-input-affix-wrapper {
    background-color: #0b2e41 !important;
    color: white !important;
    border: none !important;
    line-height: 2 !important;
    border-radius: 12px !important;
  }
  .ant-input-password-icon.anticon {
    color: #ffffff !important;
  }