@media screen and (max-width: 767px){
  .content-account{
    /*font-style: italic;*/
    font-weight: 700;
  }
  .name-logo-account{
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .name-logo-account img {
    width: 20%;
  }
  .name-logo-account h5 {
    margin: 0 15px;
  }
  .form-acc label {
    margin: 15px 0 0px 0 !important;
  }
  .form-acc {
    text-align: left;
    /*max-width: 200px;*/
    padding: 0 40px;
    margin: auto;
    /*color: #ab7600;*/
    font-style: 
    italic;
  }
  .form-acc span{
    color: #ef001b;
  }

}