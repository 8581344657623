.App {
  text-align: center;
}
body{
  /*color: white!important;*/
}
.c-pointer {
 cursor: pointer;
}

.hide {
  display: none
}

/*.globalClass_ET > jdiv {*/
  /*bottom: 70px !important*/
/*}*/

.label_39._bottom_3v._pad100_GR {
  bottom: 70px !important
}

.link-btn-default {
  padding: 8px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  line-height: 1.75;
  font-weight: 500;
  font-family: "Source Sans Pro","Helvetica Neue",Helvetica,Arial,sans-serif;
  border-radius: 4px;
  text-transform: uppercase;
  margin-top: 24px;
  font-weight: bold;
  color: #fff !important;
  background-color: #14805E;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.table-wrapper {
  overflow-x: auto
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.play-game {
  width: 100%;
  height: 100%
}

.play-game iframe {
  width: 100%;
  height: 100%;
  border: none
}

.__jivoMobileButton {
  /*position: absolute !important;*/
  /*width: 40px !important;*/
  /*height: 40px !important;*/
  /*top: 10px !important;*/
  /*right: 10px !important;*/
  bottom: 30px !important;
}
.button_1O{
  width: 40px !important;
  height: 40px !important;
  margin-bottom: 0 !important;
}
.popupBlock_1N {
  display: none !important;
}
.select-language {
  color: white !important;
}
.select-language:hover {
  color: black !important;
}
.ant-col.ant-form-item-label {
  padding: 0;
}
form.ant-form.ant-form-horizontal.form-deposit {
  padding-bottom: 30px;
  background: #fff;
}
.label-with-draw label{
  font-weight: 700;
  /*color: hsl(42deg 100% 50%)!important;*/
  
}
.label-with-draw{
  margin-bottom: 10px!important;
}
.label-with-draw  .ant-form-item-label{
  width: 43%;
  text-align: left;
  white-space: pre-wrap;
  padding: 0;
}