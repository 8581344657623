@media screen and (max-width: 767px){
  .Component-common {
    position: fixed;
    top: 0;
    height: 100%;
    background: #fff;
    z-index: 9;
    /*font-style: italic;*/
    width: 100%;
  }
  .header-detail_mb{
    display: flex;
    text-align: center;
    align-items: center;
    align-content: center;
    margin: auto;
    justify-content: center;
    padding: 0;
    /*background: #140700;*/
    min-height: 50px;
  }
  .header-detail_mb img {
    width: 40%;
  }
  .header-detail_mb h5 {
    margin: 0;
  }
  .header-detail_mb div {
    flex-basis: 33.33%;
  }
  .Component-common .title-name{
    flex-basis: 55%;
  }
  .ant-picker,label,input{
    width: 100%;
  }
  .ant-form-item .ant-form-item-label > label{
    width: 100%;
  }
  .ant-form .ant-form-item{
    width: 100%;
  }
}
#custom-primary-button-deposit{
      box-shadow: unset!important;
    /*background: unset!important;*/
    /*background: unset!important;*/
    /* border: 1px solid rgba(255, 214, 0, 0.2); */
    margin-top: unset!important;
}
