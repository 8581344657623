.boxright-wrapper {
      width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 20px;
  background: #fff;
}
.boxright-title {
      font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  margin: 0;
}
.boxright-text {
      font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #8A9AA4;
}
.boxright-styled-text {
        display: flex;
    flex-direction: row;
}