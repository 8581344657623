@media screen and (max-width: 767px) {
  .custom-mobile-deposit {
    height: 90%;
  }
}

.my-4 {
  margin-top: 4px;
  margin-bottom: 4px;
}

footer li {
  padding-right: 10px;
  margin: 5px 0;
  border-right: #606060 1px solid;
}

footer li:not(:first-child) {
  padding-left: 15px;
}

.game-slot-content {
  display: flex;
  justify-content: space-between;
  margin: 40px 120px 0px 120px;
  padding-bottom: 24px;
}

.game-slot-left {
  display: flex;
  justify-content: space-between;
}

.game-list-game {
  display: inline-grid;
}

.app-tabbar-item {
  width: 90%;
  height: 30px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  border: 5px solid #08202f;
  background: #08202f;
  color: #fff;
}

.app-tabBar-text {
  width: 100%;
  margin-left: 40px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #fff;
  margin: 0;
  display: flex;
  align-items: center;
}

footer li a {
  color: #606060;
}

.btm_box {
  padding: 0 10px 10px;
  font-weight: 500;
}

.btm_img_group {
  float: left;
}

.btm_img_group img {
  margin: 0.5rem 0.4rem 0.5rem 0;
  max-width: 28%;
}

.copyright {
  display: flex;
  justify-content: center;
}

.contact_items {
  background: url(/img/itembg4.jpg) top center no-repeat;
  background-size: 100% 100%;
  display: flex;
}

.contact_name {
  flex: 0 1 10%;
  background: url(/img/itembg5.png) top center no-repeat;
  background-size: 100% 100%;
  color: #fff;
  min-width: 80px;
  padding: 5px 10px 0 10px;
}

.itemsbox_group {
  white-space: nowrap;
  overflow: scroll;
}

.contact_box {
  color: #14805e;
  display: inline-block;
  max-width: 100px;
  padding: 15px 10px 10px 10px;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
}

.contact_box a,
.contact_box a:hover {
  color: #14805e;
}

.itemsbox_group a {
  display: inline-block;
  margin-right: 1rem;
}

.contact_box img {
  max-width: 50px;
}

.game-container {
  width: 100%;
  display: flex;
  overflow-x: auto;
}

.game-container .game-ele {
  color: #fff;
  display: flex;
  font-size: 16px;
  text-align: center;
  padding-top: 1rem;
  align-items: center;
  flex-direction: column;
  margin-right: 15px;
  max-width: 100px;
  min-width: 100px;
}

.w_1220 .main {
  width: 100%;
}

.w_1220 .main section {
  padding: 30px 0;
  margin: 50px auto;
}

.w_1220 .main .content-1 {
  display: flex;
}

.w_1220 .main .content-5 {
  display: flex;
}

.w_1220 .main .content-1 .content-1-left .title-play {
  font-size: 38px;
  font-weight: bold;
  color: hsl(0deg 100% 50%);
}

.w_1220 .main .contentst2 {
  display: flex;
  background: hsl(217deg 100% 17%);
  justify-content: space-between;
  border-radius: 25px;
  border: 2px solid hsl(250deg 83% 62%);
  padding: 10px 30px;
}

.w_1220 .main .content-2 .content-2-col {
  display: flex;
  align-items: center;
}

.w_1220 .main .content-2 .content-2-col .text {
  padding-left: 10px;
}

.w_1220 .main .content-2 .content-2-col .text h3 {
  height: 73px;
}

.w_1220 .main .content-2 .content-2-col .text p {
  font-size: 15px;
  color: hsl(29deg 99% 50%);
  margin: 0;
}

.w_1220 .main .content-3 {
  padding-bottom: 0;
  text-align: center;
}

.w_1220 .main .content-4 {
  padding-top: 0;
}

.w_1220 .main .content-3 .content-3-text {
  max-width: 500px;
  margin: 10px auto;
  text-align: center;
}

.w_1220 .main .content-3 .content-3-logo {
  display: flex;
}

.content-3 .text-title-lottery {
  text-align: center;
  max-width: 500px;
  margin: auto;
}

.content-3 .text-title-lottery h4 {
  color: red;
}

.content-3 .text-title-lottery h3 {
  font-size: 48px;
}

.w_1220 .main .content-3 .content-3-lotte .title-lotte {
  text-align: center;
}

.content-3-logo-col {
  flex-basis: 20%;
  max-width: 20%;
  padding: 0 15px;
}

.w_1220 .main .content-3 .lst-img .content-logo-col {
  padding: 0 20px;
  display: grid;
}

.w_1220 .main .content-3 .lst-img .content-logo-col img {
  border-radius: 10px;
  width: 103px;
}

.content-3-logo-col img {
  width: 100%;
}

.btn-play-now {
  text-align: center;
  margin-top: 15px;
  align-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.w_1220 .main .content-4 {
  width: 65%;
  margin: auto;
  text-align: center;
}

.w_1220 .main .content-4 .content-4-slot-game {
  margin: 20px 0;
}

.w_1220 .main .lst-img {
  display: contents;
  margin: 30px 0px;
}

.w_1220 .main .content-4 .lst-img .content-logo-col {
  flex: 1;
  padding: 0 20px;
  max-width: 143px;
}

.w_1220 .main .content-4 .lst-img .content-logo-col .frame-img {
  padding: 15px;
  border: 3px solid hsl(251deg 92% 64%);
  border-radius: 10px;
}

.w_1220 .main .content-4 .lst-img img {
  width: 103px;
}

.w_1220 .main .content-5 .content-5-left {
  display: flex;
  flex-basis: 60%;
}

.w_1220 .main .content-5 .content-5-left p {
  font-weight: 700;
  font-size: 20px;
}

/* common */
.btn-red {
  flex-shrink: 0;
  height: 35px;
  background: hsl(359deg 95% 42%);
  font-weight: 500;
  -webkit-box-shadow: 0 3px 5px 0 rgba(26, 26, 26, 0.23);
  box-shadow: 0 3px 5px 0 rgba(26, 26, 26, 0.23);
  border-radius: 8px;
  border-width: 0;
  color: #fff;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  justify-content: space-around !important;
  display: flex !important;
  font-size: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}

.btn-start .button .text {
  color: white;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.content-left {
  flex-basis: 50%;
  padding-left: 11px;
}

.content-right {
  flex-basis: 50%;
  max-width: 50%;
}

.content-5-right {
  flex-basis: 40% !important;
}

.btn-gr-menu a {
  color: #fff;
}

.btn-gr-menu a:hover {
  color: hsl(359deg 95% 42%);
}

.refreshWallet {
  border-radius: 15px !important;
  line-height: 10px;
  height: 20px;
}

@media screen and (max-width: 767px) {
  .content-lst-game .icon-img {
    text-align: center;
  }

  .content-lst-game .icon-img img {
    width: 50px;
  }

  h6 {
    font-size: 14px;
  }

  .lst-game {
    width: 100vw;
  }

  .content-lst-game {
    display: flex;
  }

  .content-lst-game h6 {
    margin: 0;
  }

  .content-2-col {
    flex: 1 1;
    padding: 5px 1px 5px 1px;
    border: solid 1px #fdfdfd;
    border-radius: 5px;
    background: #f1f1f1;
    font-weight: bold;
  }

  .content-3-lotte {
    display: flex;
    flex-wrap: wrap;
  }

  .content-3-lotte .content-3-logo-col {
    flex-basis: 33.3%;
    max-width: 33.3%;
    padding: 10px;
  }

  .content-text {
    max-width: 296px;
    margin: 25px auto;
    text-align: left;
    font-size: 14px;
  }

  .menu_mb {
    position: relative;
    background-color: #ffe27f !important;
  }

  .lst-wallet {
    display: flex;
    align-items: center;
    background: #03607c;
    padding: 10px 0;
    text-align: center;
    color: #fff;
  }

  .lst-wallet .load-wallet {
    flex-basis: 36%;
    border-right: 1px solid #80808087;
  }

  .lst-wallet .load-wallet .wallet-top {
    justify-items: center;
    align-items: center;
  }

  .lst-wallet img {
    width: 32%;
  }

  .lst-wallet .scale-img {
    width: 50%;
  }

  .lst-wallet p {
    font-weight: 600;
    font-size: 12px;
    margin: 0;
  }

  .wallet-top .content-text-wallet {
    flex-basis: 40%;
    text-align: left;
  }

  .item-wallet {
    flex-basis: 20%;
  }

  .wallet-bottom {
    padding: 0 1px;
  }

  .wallet-bottom button {
    border-radius: 10px;
    color: #fff;
    background: #000547;
    border: 1px solid #fff;
    font-weight: 600;
    width: 100%;
  }

  .lst-img {
    flex-wrap: wrap;
    justify-content: center;
  }

  .lst-img .content-logo-col {
    flex-basis: calc(100% / 3);
    padding: 10px;
  }
}

/* How To Register */
.howto {
  max-width: 1224px;
  width: 100%;
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ::-webkit-scrollbar {
    width: 5px;
    height: 3px;
  }

  @media (max-width: 575px) {
    margin: 0 0 30px;
  }
}

.howto-register {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1170px) {
  .howto-register {
    width: 990px;
    margin: 0 auto;
  }
}

.howto-text {
  width: 100%;
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #000;
}

@media (max-width: 575px) {
  .howto-text {
    font-size: 16px;
    line-height: 24px;
  }
}

.howto-title {
  width: 100%;
  font-size: 32px;
  line-height: 42px;
  font-weight: 600;
  color: #000;
}

@media (max-width: 575px) {
  .howto-title {
    font-size: 24px;
    line-height: 32px;
  }
}

.howto-list-register {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

@media (max-width: 1170px) {
  .howto-list-register {
    width: 990px;
    justify-content: center;
  }
}

.howto-card-text {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  text-align: center;
  margin: 0;
  color: #000;
}

@media (max-width: 575px) {
  .howto-card-text {
    font-size: 14px;
    line-height: 18px;
  }
}

.howto-styled-img {
  padding: 30px 50px;
}

@media (max-width: 575px) {
  .howto-styled-img {
    width: 95%;
    padding: 9px 0px 6px 50px;
  }
}

.howto-style-imgcard {
  width: 100px;
  height: 100px;
  margin-bottom: 5px;
}

@media (max-width: 575px) {
  .howto-style-imgcard {
    width: 80px;
    height: 80px;
  }
}

.theme-box1 {
  width: 280px;
  height: 200px;
  padding: 10px 20px 20px;
  background: #fbe6e6;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 575px) {
  .theme-box1 {
    width: 240px;
    height: 150px;
  }
}

.theme-box2 {
  width: 280px;
  height: 200px;
  padding: 10px 20px 20px;
  background: #edfcf4;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 575px) {
  .theme-box2 {
    width: 240px;
    height: 150px;
  }
}

.theme-box3 {
  width: 280px;
  height: 200px;
  padding: 10px 20px 20px;
  background: #fbf4e6;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 575px) {
  .theme-box3 {
    width: 240px;
    height: 150px;
  }
}

hr {
  margin: 50px 0;
}

.thumbnail-wrapper {
  margin-top: -350px;
  height: 85px;
  width: 90%;
  margin-left: 60px;
}

.thumbnail-wrapper .slick-track .slick-slide {
  text-align: center;
}

.thumbnail-wrapper .slick-track .slick-slide img {
  display: flex;
  margin: 0px auto;
  height: 5vh;
  width: 30%;
  border-radius: 5%;
  cursor: pointer;
}

/* Colection */
.colection-slide {
  width: 100%;
  margin: 30px 0;
}

@media (max-width: 575px) {
  .colection-slide {
    margin: 20px 0;
  }
}

@media (max-width: 480px) {
  .colection-slide {
    padding: 0 14px;
  }
}

.colection-tilte {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

@media (max-width: 575px) {
  .colection-tilte {
    padding: 5px 0;
  }
}

.colection-slide-text-title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: #000;
  margin: 0;
}

@media (max-width: 575px) {
  .colection-slide-text-title {
    font-size: 16px;
    line-height: 24px;
  }
}

.colection-text-more {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-decoration: underline;
  color: #000;
  cursor: pointer;
  margin: 0;
}

@media (max-width: 575px) {
  .colection-text-more {
    font-size: 14px;
    line-height: 24px;
  }
}

.colection-text-more:hover {
  color: #ff8100;
}

.colection-slide-show {
  position: relative;
  max-width: 100%;
}

@media (max-width: 1300px) {
  .colection-slide-show {
    overflow: hidden auto;
  }
}

.colection-head-slide-show {
  position: relative;
  max-width: 100%;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.slick-slide {
  cursor: pointer;
  padding: 0 10px;
}

@media (max-width: 1200px) {
  .slick-slide {
    padding: 0 9px;
  }
}

.colection-img-slide {
  max-width: 280px;
}

@media (max-width: 480px) {
  .colection-img-slide {
    max-width: 100%;
  }
}

/* App Downdow */
.app-download {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 100px 0 50px;
  gap: 20px;
}

@media (max-width: 925px) {
  .app-download {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 575px) {
  .app-download {
    margin: 50px 0;
  }
}

.app-download-left {
  width: 40%;
}

@media (max-width: 925px) {
  .app-download-left {
    width: 100%;
  }
}

.app-download-left-text {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: #000;
}

@media (max-width: 575px) {
  .app-download-left-text {
    font-size: 18px;
    line-height: 24px;
  }
}

.app-download-left-title {
  max-width: 460px;
  font-size: 32px;
  line-height: 42px;
  font-weight: 600;
  color: #000;
}

@media (max-width: 575px) {
  .app-download-left-title {
    max-width: 375px;
    font-size: 24px;
    line-height: 32px;
  }
}

.app-download-left-detail {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #7f919e;
  padding-bottom: 10px;
}

@media (max-width: 575px) {
  .app-download-left-detail {
    font-size: 13px;
    line-height: 18px;
  }
}

.app-download-groupqr {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;
}

@media (max-width: 375px) {
  .app-download-groupqr {
    gap: 10px;
  }
}

.app-download-styledqr-dowload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  gap: 20px;
}

@media (max-width: 370px) {
  .app-download-styledqr-dowload {
    width: 130px;
  }
}

.app-download-appapple {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  border: 1px solid #ccc;
  padding: 5px 5px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #000;
}

.app-download-appIntrstions {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: #1358c7;
  cursor: pointer;
}

@media (max-width: 575px) {
  .app-download-appIntrstions {
    font-size: 14px;
    line-height: 18px;
  }
}

.app-download-right {
  width: 60%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

@media (max-width: 925px) {
  .app-download-right {
    width: 100%;
  }
}

.app-download-right-left {
  width: 45%;
}

@media (max-width: 575px) {
  .app-download-right-left {
    /* width: 100%; */
    display: none;
  }
}

.app-download-right-right {
  width: 55%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

@media (max-width: 575px) {
  .app-download-right-right {
    width: 100%;
  }
}

.app-download-right-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.featured-slots-body {
  background-color: #0d1b2a;
  color: white;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0px 0px 20px 0px;
  border-bottom: 3px solid rgba(255, 215, 0, 0.5);
}

.featured-slots-container {
  width: 90%;
  max-width: 1200px;
  margin: auto;
  padding: 20px 20px 20px 0px;
}

/* HEADER */
.featured-slots-header {
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  /* border-left: 4px solid gold; */
  margin-bottom: 15px;
}

/* .featured-slots-header img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
} */

/* TAB CONTAINER */
.featured-slots-tab-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: 3px solid rgba(255, 215, 0, 0.5);
  /* Line vàng mờ */
}

.featured-slots-tab-buttons {
  display: flex;
  gap: 10px;
  position: relative;
}

.featured-slots-tab-button {
  background: linear-gradient(to right, rgba(6, 32, 51, 1) 0%, rgba(4, 23, 38, 1) 100%);
  border: 1px solid #ffe27f;
  border-bottom: none;
  color: #ffe27f;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 8px 8px 0 0;
  min-width: 150px;
  text-align: center;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
}

.featured-slots-tab-button.active {
  background: linear-gradient(to left, rgba(248, 236, 127, 1) 0%, rgba(201, 158, 66, 1) 100%);
  color: #0a1941;
  border: 1px solid transparent;
}

.featured-slots-tab-button.active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 100%;
  height: 3px;
  background-color: gold;
}

.featured-slots-more-games-btn {
  background: linear-gradient(to left, rgba(248,236,127,1) 0%, rgba(201,158,66,1) 100%);
  border: none;
  color: #0a1941;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 15px;
  padding: 5px 15px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.featured-slots-more-games-btn:hover {
  filter: brightness(1.05);
  transform: scale(0.98);
}

/* GAME GRID */
.featured-slots-game-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  justify-items: center;
}

.featured-slots-more-game-grid {
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  justify-content: center;
  display: flex;
}
.featured-slots-more-game-mgbox {
  display: inline-block;
  position: relative;
  max-width: 290px;
  margin: 0 10px;
  overflow: hidden;
  cursor: pointer;
}
.featured-slots-more-game-mgimg-hover {
	opacity: 0;
	visibility: hidden;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	transition: 0.2s;
	transform: translateY(90px);
}
.featured-slots-more-game-mgbox:hover .featured-slots-more-game-mgimg-hover {
	opacity: 1;
	visibility: visible;
	transition: 0.2s;
	transform: none;
}

.featured-slots-more-game-item {
  width: 220px;
  height: 150px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  margin: 0px 20px;
}

.featured-slots-more-game-item-hover {
  /* transition: 0.2s; */
  transform: translateY(90px);
  height: 150px;
}

.featured-slots-more-game-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.5s ease-in-out;
}

/* Hiệu ứng hover đổi hình ảnh */
.featured-slots-more-game-item:hover img.default {
  opacity: 0;
}

.featured-slots-more-game-item img.hover {
  position: absolute;
  opacity: 0;
}

.featured-slots-more-game-item:hover img.hover {
  opacity: 1;
}

.featured-slots-game-item {
  width: 150px;
  height: 100px;
  background-color: #1b2a41;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s;
  position: relative;
}

.featured-slots-game-item:hover {
  transform: scale(1.05);
}

.featured-slots-game-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.5s ease-in-out;
}

/* Hiệu ứng hover đổi hình ảnh */
.featured-slots-game-item:hover img.default {
  opacity: 0;
}

.featured-slots-game-item img.hover {
  position: absolute;
  opacity: 0;
}

.featured-slots-game-item:hover img.hover {
  opacity: 1;
}

/* TAB CONTENT */
.featured-slots-tab-content {
  display: none;
}

.featured-slots-tab-content.active {
  display: block;
}

/* SEE MORE BUTTON */
.featured-slots-see-more {
  background-color: gold;
  color: black;
  padding: 4px 8px;
  border-radius: 5px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  width: max-content;
  cursor: pointer;
  transition: 0.3s;
  border: none;
}

.featured-slots-see-more:hover {
  background-color: darkgoldenrod;
}

/* PLAY NOW BUTTON */
.featured-slots-play-now {
  background-color: gold;
  color: black;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: max-content;
  cursor: pointer;
  transition: 0.3s;
  border: none;
}

.featured-slots-play-now:hover {
  background-color: darkgoldenrod;
}

.games-button {
  display: flex;
  align-items: center;
  background: linear-gradient(to right, #0d1b2a, #1b2a41); /* Hiệu ứng gradient */
  padding: 12px 25px;
  border-radius: 5px;
  color: white;
  font-family: Arial, sans-serif;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  box-shadow: inset -2px -2px 5px rgba(255, 255, 255, 0.1), inset 2px 2px 5px rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
  transition: 0.3s ease-in-out;
}

/* Thanh vàng bên trái với góc cắt */
.games-button::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 6px;
  height: 100%;
  background: gold;
  clip-path: polygon(0 0, 100% 20%, 100% 80%, 0 100%);
}

.games-button:hover {
  background: linear-gradient(to right, #1b2a41, #0d1b2a);
  transform: scale(1.05);
}

.games-button img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.fs-14 {
  font-size: 14px;
}
.w-24 {
  width: 24px !important;
}

.h-24 {
  height: 24px !important;
}
.w-15 {
  width: 15px !important;
}

.h-15 {
  height: 15px !important;
}

.b-none {
  border: none !important;
}

.game-description-body {
  background-color: #0d1b2a;
  color: white;
  font-family: Arial, sans-serif;
  border-top: 3px solid #ffe27f;
  margin: 0;
  padding: 0px 0px 20px 0px;
  font-size: 14px;
  font-weight: 700;
}

.game-description-container {
  padding: 10px 30px
}

.footer-body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #0d1b2a;
  color: #ffffff;
}

.footer {
  background-color: #081122;
  padding: 30px 40px 0px 40px;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px dashed #ffffff;
  border-bottom: 1px dashed #ffffff;
  padding: 20px 0;
}

.footer-section {
  width: 30%;
  text-align: left;
}

.footer-h4 {
  font-size: 14px;
  margin-bottom: 10px;
  color: #545d69;
  text-align: left;
}

.footer-logos, .footer-logos-small, .footer-payment-icons, .footer-social-icons, .footer-providers {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
}

.footer-logos-small {
  height: 40px !important;
  max-width: 70px !important;
}

.footer-logos-medium {
  height: 40px !important;
  max-width: 130px !important;
}

.footer-logos-large {
  height: 40px !important;
  max-width: 150px !important;
}

.footer-social-icons img {
  height: 40px;
  max-width: 40px;
}
.footer-logos img, .footer-payment-icons img, .footer-providers img {
  height: 40px;
  max-width: 100px;
}

/* Phần Border Left - Right Chỉ Ở Giữa */
.footer-middle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.footer-border-left, .footer-border-right {
  width: 2px;
  height: 130px;
  border-left: 1px dashed #ffffff;
}

.footer-border-content {
  padding: 20px;
  text-align: center;
}

.footer-providers {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: left;
}

.footer-providers img {
  height: 40px;
  max-width: 90px;
}
.ml-i-5 {
  margin-left: -5px;
}
.ml-i-20 {
  margin-left: -20px;
}

.footer-copy-right {
  border-top: 1px dashed #ffffff;
  padding: 20px 0;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
}


.howto-tab-content {
  position: relative;
  margin-top: -40px;
  margin-right: -80px;
}
/* 
.howto-tab-content>.howto-tab-pane {
  display: none;
} */
.howto-fade:not(.show) {
  opacity: 0;
}
.howto-fade {
  transition: opacity .15s linear;
}
.howto-tab-content {
  > .howto-tab-pane {
    display: none;
  }
  > .active {
    display: block;
  }
}
.howto-sports-intro-wrap {
  width: 690px;
  margin: 0 auto;
}
.howto-sg-caption {
  border-bottom: 4px solid #d6b987;
  background: rgb(11, 41, 63, 0.8);
  color: #fff;
  font-size: 17px;
  padding: 15px 20px;
  position: absolute;
  max-width: 231px;
  top: 35%;
}
.howto-sg-chrt-img {
  text-align: right;
  position: relative;
  max-width: 70%;
  margin: 0 0 0 auto;
}
.howto-sg-text {
  max-width: 74%;
  margin-top: 12px;
}

.featured-slots-title-wrap {
  position: relative;
  margin: 0 0 20px;
}
.featured-slots-title-text {
  position: absolute;
  color: #fff;
  font-weight: 800;
  font-size: 19px;
  text-transform: uppercase;
  left: 30px;
  top: 7px;
  display: flex;
  align-items: center;
}
.featured-slots-title-text img {
  height: 28px;
  width: 28px;
  margin-right: 15px;
}

.howto-app-tabBar-text {
  color: #ffffff
}