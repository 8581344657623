@media screen and (max-width: 767px){
  .content-detail-banking{
    font-weight: 700;
    /*font-style: italic;*/
    font-size: 22px;
  }
  .content-detail-banking .label-bank-detail {
    padding: 10px;
    background: #03607c;
    text-align: center;
    color: #fff;
  }
  .content-detail-banking .value-detail-banking {
    padding: 20px;
    font-weight: 600 !important;
}
  .row-content-bank {
    display: flex;
  }
  .row-content-bank img {
    width: 25%;
  }
  .row-content-bank .title {
    flex-basis: 50%;
}

}